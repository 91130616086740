<template>
  <v-app style="background-color: #F2F2F2">
    <v-layout row wrap align-center justify-center>
      <v-flex lg4 md5 sm7 xs7 class="text-center">
        <img src="@/assets/logo_onebox-horizontal-png.png" width="45%" height="45%" alt="OneBox" />
        <br />
        <br />
        <v-progress-linear
          background-color="light-blue lighten-4"
          color="success"
          indeterminate
          rounded
        ></v-progress-linear>
        <br />
        <p class="text-center">รอสักครู่</p>
      </v-flex>
    </v-layout>
  </v-app>
</template>

<script>
import Swal from "sweetalert2/dist/sweetalert2.js";
import "sweetalert2/src/sweetalert2.scss";

const Toast = Swal.mixin({
  toast: true,
  position: "top-end",
  showConfirmButton: false,
  timer: 3000
});

export default {
  data: function() {
    return {};
  },
  created() {},
  computed: {},
  watch: {},
  methods: {

    loginwithsharetoken() {
      if ("token" in this.$route.query) {
        this.axios
          .post(
            process.env.VUE_APP_SERVICE_AUTHORIZE_API +
              "/api/login_by_sharetoken_oneid",
            { sharetoken:  this.$route.query.token }
          )
          .then(response => {
            if (response.data.status == "OK") {
              // localStorage.setItem(
              //   "token",
              //   response.data.result["accesstoken"]
              // );
              this.localsync_login({all_biz: response.data.result.biz, username: response.data.result.user_info.username, oneid: response.data.result.user_info.user_id})
              this.$cookies.set('token', window.btoa(response.data.result["accesstoken"]));
              this.$cookies.set('token_auth', true);
              this.$store.dispatch("authorize_granted", response.data.result);
              this.$router.push("/mydrive");
            } else {
              Toast.fire({
                icon: "error",
                title: response.data.errorMessage
              });
              this.$router.push("/");
            }
          })
          .catch(error => {
            console.log(error);
            Toast.fire({
                icon: "error",
                title: JSON.stringify(error)
              });
            this.$router.push("/");
          });
      } else {
        this.$router.push("/mydrive");
      }
    },
    localsync_login (data) {
      try{
        let drive_mapp = data.all_biz.map((itemMap) => { return {
          user_type: 'business',
          biz_id: itemMap.id,
          onbox_biz_id: itemMap.business_id
        } })

        let payload = {
          one_id: data.oneid,
          username: data.username,
          drive_info: [...drive_mapp]
        }
        var result = this.axios.post(
          "https://uatbox-dedicate.one.th/localsync/api/sent_one_info",
          payload,
          {
            headers: {
              Authorization:
                `Bearer ${process.env.VUE_APP_BEARER_TOKEN}`,
            },
          }
          )
        console.log('resultt localsync ', result)
      } catch (err) {
        console.log("error localsyncc ", err)
      }
  }
  },
  mounted() {
    this.loginwithsharetoken()
  }
};
</script>
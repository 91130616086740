<template>
  <v-row justify="center">
    <!-- Old Version -->
    <!-- <v-dialog v-model="shower" scrollable persistent max-width="700px" style="display: none;">
      <v-card>
        <v-card-title>รายการเอกสารที่ต้องการกู้คืน <v-spacer></v-spacer>
          <v-icon :color="color.theme" dense dark size="35">restore</v-icon>
        </v-card-title>
        <v-divider></v-divider>
        <v-card-text style="overflow-x:hidden; overflow-y:hidden;" class="ml-n2">
          <br />
           <div class="text-center"><v-chip class="pt-4 pl-2 ma-2"><p ><v-icon :color="color.theme"> folder </v-icon><b> โฟลเดอร์ที่เลือก {{ count_folder }} โฟลเดอร์</b></p></v-chip>
           <v-chip class="pt-4 pl-2 ma-2"><p ><v-icon :color="color.theme">mdi-file</v-icon><b> ไฟล์ที่เลือก {{ count_file }} ไฟล์</b></p></v-chip>
           <p v-if="check_restore === true">กำลังกู้คืน {{count_restoring}} / {{countshowrestore}} รายการ</p>
           Comment
          <v-chip class="pt-4 pl-2 ma-2"><p ><v-icon color="green"> mdi-delete</v-icon><b> มีสิทธิ์ลบ {{ countshowdelete }} ไฟล์</b></p></v-chip>
          //
          Comment
          <v-chip class="pt-4 pl-2 ma-2"><p ><v-icon color="red"> mdi-delete-off </v-icon><b> {{ countshowcannotdelete }} {{$t('dialogmultidelete.title2')}}</b></p></v-chip>
          //
        </div> 
          <div class="scroll" style="overflow-x:hidden; overflow-y:hidden;">
          <v-list>
            โชว์ไฟล์สามารถลบได้
            <v-list-item v-for="item in showrestore" :key="item.title">
              <v-list-item-action @mouseover="item.show_name = true" @mouseleave="item.show_name = false">
                <v-icon large color="primary">{{ item.file_type === "folder" ? "folder" : "mdi-file " }}</v-icon>
              </v-list-item-action>
              <v-badge
                :value="item.show_name"
                color="grey"
                :content="item.file_name"
                
                transition="slide-y-transition"
              ></v-badge>
              <v-list-item-content>
                 
                <v-list-item-title @mouseover="item.show_name = true" @mouseleave="item.show_name = false">
                  {{ item.file_name }}</v-list-item-title
                >
                <v-list-item-subtitle>
                  <v-progress-linear
                    v-if="check_show_progress === true && item.file_value !== 100 && item.file_value !== 500"
                    :value="item.file_value"
                    color="light-blue"
                  ></v-progress-linear>
                </v-list-item-subtitle>
              </v-list-item-content>
              โชว์ว่ามีสิทธิ์ลบหรือไม่
              Comment
              <v-list-item-action v-if="check_show_progress === false">
                <v-chip
                  class="ma-2 font-weight-bold"
                  :color="item.permission_delete === true ? 'light-blue' : 'red'"
                  label
                  outlined
                >
                 {{ item.permission_delete === true ? $t('dialogmultidelete.canbedelete') : $t('dialogmultidelete.cannotdelete') }}
                </v-chip>
              </v-list-item-action>
              //
              โชว์สถานะการลบว่าลบสำเร็จหรือไม่
              <v-list-item-action >
                <v-chip v-if="item.file_value !== 500" class="ma-2 font-weight-bold" :color="item.file_status === 'ต้องการกู้คืน' || item.file_status === 'กำลังกู้คืน' ? color.theme : item.file_status === 'กู้คืนไฟล์สำเร็จ' ? 'green' : item.file_status === 'ไม่สามารถกู้คืนไฟล์นี้ได้' ? 'red' : '#F37958' " label outlined>
                  {{ item.file_status }}
                </v-chip>
                <v-chip v-else class="ma-2 font-weight-bold" color="red" label outlined>
                  {{ item.file_status }}
                </v-chip>
              </v-list-item-action>
            </v-list-item>
          </v-list>
          </div>
        </v-card-text>
        <v-divider></v-divider>
        <v-card-actions>
          <v-spacer></v-spacer>
          ปุ่มก่อนกดยืนยันที่จะลบหรือกดปิดdialog
          <v-btn
            v-if="check_show_progress === false && check_show_button === false"
            color="red"
            outlined
            @click="$emit('closedialog'),$emit('closebuttonmulti')"
            >{{ $t("changeName.close") }}</v-btn
          >
          <v-btn
            :color="color.theme"
            v-if="check_show_progress === false && check_show_button === false"
            :dark="color.darkTheme"
            @click="fn_multirestore(), (check_show_button = true), (check_show_progress = true), (check_restore =true)"
            >กู้คืน</v-btn
          >
          <v-btn color="red" :disabled="check_dis_button" v-if="check_show_button === true" outlined @click="$emit('closedialogreload'),$emit('closebuttonmulti'),(check_restore = false)">{{$t('dialogmultidelete.buttonclose')}}</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog> -->

    <!-- New Version -->
    <!-- style="overflow-y: hidden"  -->
    <v-dialog
      v-if="resolutionScreen >= 500" 
      v-model="shower" 
      scrollable
      persistent max-width="740px"
      >
      <v-card>
        <v-card-title>
          {{$t("multirecovery.list_filefolder_recov")}}<v-spacer></v-spacer>
          <v-icon :color="color.theme" dense dark size="35" class="mr-3">restore</v-icon>
          <!-- <v-chip  class="pt-4 pl-2 ma-2"><p ><v-icon :color="color.theme"> folder </v-icon><b> {{ count_folder }} {{$t("multirecovery.folder")}}</b></p></v-chip>
          <v-chip   class="pt-4 pl-2 ma-2"><p ><v-icon :color="color.theme">mdi-file</v-icon><b> {{ count_file }} {{$t("multirecovery.file")}}</b></p></v-chip>  -->
        </v-card-title>
        <v-divider></v-divider>
        <!-- ref="scrollCard" -->
        <v-card-text class="ml-n2" ref="scrollCard">
          <br />
          <v-card-actions>
            <!-- row v-if="tab === 0 && multiitemwaitmove_v2.filter((item) => item.file_type === 'folder').length > 0" class="ml-2" -->
            <v-layout  v-if="group_recovery.filter((item) => item.file_status === $t('multirecovery.waittomove')).length > 0" >
              <v-icon color="#FFCC00">
                mdi-alert-circle
              </v-icon>
              <span class="ml-2">
                รอย้ายไฟล์ในกรณีที่โฟลเดอร์ต้นทางถูกลบหรือถูกเปลี่ยนสิทธิ์การเข้าถึง
              </span>
            </v-layout>
            <v-spacer></v-spacer>
            <v-chip  class="pt-4 pl-2 ma-2"><p ><v-icon :color="color.theme"> folder </v-icon><b> {{ count_folder }} {{$t("multirecovery.folder")}}</b></p></v-chip> 
            <v-chip   class="pt-4 pl-2 ma-2"><p ><v-icon :color="color.theme">mdi-file</v-icon><b> {{ count_file }} {{$t("multirecovery.file")}}</b></p></v-chip>
          </v-card-actions>
          <v-tabs v-model="tab" >
            <v-tab v-show="!disable_tab_nonesuccess" :disabled="disable_treeview_loading === true || disable_tab_nonesuccess">{{ $t("multirecovery.tabnonerecovery_success") }}</v-tab>
            <v-tab v-show="!disable_tab_success" :disabled="disable_treeview_loading === true || disable_tab_success" @click="show_all_select = false">{{ $t("multirecovery.tabrecovery_success") }}</v-tab>
            <v-tab :disabled="disable_treeview_loading === true">{{ $t("multirecovery.taball") }} ({{ showrestore.length }})</v-tab>
          </v-tabs>
          <v-tabs-items v-model="tab">
            <v-tab-item>
              <v-list v-if="nonsuccess_loading === true">
                <v-list-item class="pt-4 pb-4" >
                  <v-overlay  absolute color="white" dark  >
                    <v-progress-circular
                          indeterminate
                          size="35"
                          :color="color.theme"></v-progress-circular>
                    </v-overlay>
                </v-list-item>
              </v-list>
              <!-- <template>
                style="max-height: 550px; overflow-y: scroll;" class="v-card-tab-waiting" ref="scrollCardWait"
              </template> -->
              <v-card-text  v-else ref="scrollCardWait" >
                  <v-treeview
                  ref="tree"
                  selection-type="leaf"
                  return-object
                  multiple-active
                  class="v-treeview-movefile"
                  :items="nonsuccess_group_recovery"
                  :open.sync="openIds"
                >
                  <template v-slot:prepend="{item}">
                    <v-layout row class="ml-1 my-n2">
                        <v-checkbox
                        :disabled="disable_treeview_loading === true"
                        v-model="multiitemwaitmove_v2"
                        :value="item"
                        v-if="item.role === 'P' && item.file_status === $t('multirecovery.waittomove') && check_move_dis_button" />
                        <v-icon large :color="item.file_icon[1]">
                            {{ item.file_icon[0] }}
                        </v-icon>
                    </v-layout>
                  </template>
                  <template v-slot:label="{item}">
                    <v-list-item class="ml-n1">
                      <v-list-item-content>
                        <v-list-item-title >
                          <span class="v-treeview-node__label">
                            {{item.file_name | subStr }}
                          </span>
                        </v-list-item-title>
                        <v-list-item-subtitle v-if="item.hasOwnProperty('message') && item.role === 'P'">
                          <span class="v-treeview-node__label">
                            {{item.message }}
                          </span>
                        </v-list-item-subtitle>
                      </v-list-item-content>
                    </v-list-item>
                  </template>
                  <!-- <template v-slot:append="{item}">
                    <v-chip small>
                      {{ item.file_status }}
                    </v-chip>
                  </template> -->
                  <!-- New V -->
                  <template v-slot:append="{item}">
                    <v-chip 
                    :color="item.file_status === $t('multirecovery.recovery_cancel') ? 'error' : undefined"
                    v-if="item.file_status === $t('multirecovery.waittomove') || 
                    item.file_status === $t('multirecovery.recovfail') || 
                    item.file_status === $t('multirecovery.recovery_cancel')" small>
                      {{ item.file_status }} 
                    </v-chip>
                    <v-progress-circular
                      v-else-if="item.file_status === $t('multirecovery.movingfilefolder')"
                      indeterminate
                      size="20"
                      :color="color.theme"></v-progress-circular>
                    <v-icon color="green" v-else-if="item.file_status === $t('multirecovery.recovsuccess')"> mdi-check </v-icon>
                    <!-- <v-icon color="red" v-else-if="item.file_status === $t('multirecovery.recovfail')"> mdi-close </v-icon> -->
                  </template>
                  </v-treeview>
              </v-card-text>
            </v-tab-item>
            <v-tab-item>
              <v-card-text>
                <v-list>
                  <v-list-item v-for="item in success_rocovery" :key="item.file_id">
                      <v-icon large :color="item.file_icon[1]">{{ item.file_icon[0]}}</v-icon>
                      <v-list-item-content>
                        <v-list-item-title class="ml-4" @mouseover="item.show_name = true" @mouseleave="item.show_name = false">
                        {{ item.file_name }}
                        </v-list-item-title>
                        <!-- <v-list-item-subtitle class="ml-4" v-if="
                        item.file_status_message === 'This file has been moved' || 
                        item.file_status_message === 'This folder has been moved'">
                          {{$t("multirecovery.filefoldermoved")}}
                        </v-list-item-subtitle> -->
                        <v-list-item-subtitle 
                          class="ml-4"
                          v-if="item.file_status === 'รอย้ายไฟล์' || item.file_status === 'Wait to move'">
                          {{$t("multirecovery.filenoparent")}}
                        </v-list-item-subtitle>
                        <!-- <v-list-item-subtitle class="ml-4">
                          <v-progress-linear
                            v-if="check_show_progress === true && item.file_value !== 100 && item.file_value !== 500"
                            :value="item.file_value"
                            color="light-blue"
                          ></v-progress-linear>
                        </v-list-item-subtitle> -->
                    </v-list-item-content>
                    <v-list-item-action >
                      <v-chip v-if="item.file_value !== 500" class="ma-2 font-weight-bold" 
                      :color="
                      item.file_status === $t('multirecovery.document_recovering') || item.file_status === $t('multirecovery.recovering') ? color.theme : 
                      item.file_status === $t('multirecovery.document_recovered') || item.file_status === $t('multirecovery.recovsuccess') ? 'green' : 
                      item.file_status === $t('multirecovery.document_fail_recovered') || item.file_status === $t('multirecovery.recovfail') ? 'red' : 
                      item.file_status === $t('multirecovery.document_allowpermission') || item.file_status === $t('multirecovery.allowpermission')  ? color.theme :'#F37958' " label outlined>
                        {{ item.file_status }}
                      </v-chip>
                      <v-chip v-else class="ma-2 font-weight-bold" color="red" label outlined>
                        {{ item.file_status }}
                      </v-chip>
                    </v-list-item-action>
                  </v-list-item>
                </v-list>
            </v-card-text>
            </v-tab-item>
            <v-tab-item v-if="$router.app['_route']['name'] === 'trashinbox' || $router.app['_route']['name'] === 'trashoutbox'" style="overflow-x: hidden;">
              <!-- <template v-if="treeviewfile" > 
                <div class="mt-5">
                  <v-treeview
                  ref="tree"
                  return-object
                  multiple-active
                  :items="group_recovery"
                >
                  <template v-slot:prepend="{item}">
                      <v-icon large :color="item.file_icon[1]">
                        {{ item.file_icon[0] }}
                      </v-icon>
                  </template>
                  <template v-slot:label="{item}">
                    <v-list-item class="ml-n2">
                      <v-list-item-content>
                        <v-list-item-title >
                          <span class="v-treeview-node__label">
                            {{item.file_name | subStr }}
                          </span>
                        </v-list-item-title>
                      </v-list-item-content>
                    </v-list-item>
                  </template>
                  </v-treeview>
                </div>
              </template> -->
              <v-list>
                <v-list-item v-for="item in showrestore" :key="item.file_id">
                    <v-icon @mouseover="item.show_name = true" @mouseleave="item.show_name = false" large :color="item.file_icon[1]">{{ item.file_icon[0]}}</v-icon>
                    <v-badge
                      :value="item.show_name"
                      color="grey"
                      :content="item.file_name"
                      transition="slide-y-transition"
                    ></v-badge>
                    <v-list-item-content>
                      <v-list-item-title class="ml-4" @mouseover="item.show_name = true" @mouseleave="item.show_name = false">
                        {{ item.file_name }}
                      </v-list-item-title>
                      <v-list-item-subtitle class="ml-4" >
                        <v-progress-linear
                          v-if="check_show_progress === true && item.file_value !== 100 && item.file_value !== 500"
                          v-show="item.permission_account_v2 !== '05' || '06'"
                          :value="item.file_value"
                          color="light-blue"
                        ></v-progress-linear>
                        <span v-else>
                          {{ item.file_status === $t("multirecovery.recovsuccess") || item.file_status === $t("multirecovery.recovfail")
                           ? '' : item.file_status_message }}
                        </span>
                      </v-list-item-subtitle>
                    </v-list-item-content>
                    <v-list-item-action >
                      <v-chip v-if="item.file_value !== 500" class="ma-2 font-weight-bold" 
                      :color="
                      item.file_status === $t('multirecovery.document_recovering') ? color.theme : 
                      item.file_status === $t('multirecovery.document_recovered') ? 'green' : 
                      item.file_status === $t('multirecovery.recovfail') ? 'red' : 
                      item.file_status === $t('multirecovery.document_allowpermission') ? color.theme :'#F37958' " label outlined>
                        {{ item.file_status }}
                      </v-chip>
                      <v-chip v-else  class="ma-2 font-weight-bold" color="red" label outlined>
                        {{ item.file_status }}
                      </v-chip>
                    </v-list-item-action>
                </v-list-item>
              </v-list>
            </v-tab-item>
            <v-tab-item v-else>
              <v-card-text ref="scrollCardAll" > 
                <v-treeview
                  ref="tree"
                  return-object
                  multiple-active
                  class="v-treeview-allfile"
                  :items="group_recovery"
                >
                  <template v-slot:prepend="{item}">
                      <v-icon large :color="item.file_icon[1]">
                        {{ item.file_icon[0] }}
                      </v-icon>
                  </template>
                  <template v-slot:label="{item}">
                    <v-list-item class="ml-n2">
                      <v-list-item-content>
                        <v-list-item-title >
                          <span class="v-treeview-node__label">
                            {{item.file_name | subStr }}
                          </span>
                        </v-list-item-title>
                      </v-list-item-content>
                    </v-list-item>
                  </template>
                  <template v-slot:append="{item}">
                    <v-chip v-if="item.file_status === $t('multirecovery.allowpermission') && item.role === 'P'" small>
                      {{ $t('multirecovery.allowpermission') }}
                    </v-chip>
                    <v-chip v-else-if="item.file_status === $t('multirecovery.notallowpermission') && item.role === 'P'" small>
                      {{ $t('multirecovery.notallowpermission') }}
                    </v-chip>
                    <v-progress-circular
                      v-else-if="item.file_status === $t('multirecovery.recovering')"
                      indeterminate
                      size="20"
                      :color="color.theme"></v-progress-circular>
                      <v-chip 
                      :color="item.file_status === $t('multirecovery.recovery_cancel') ? 'error' : undefined"
                      v-else-if="
                      item.file_status === $t('multirecovery.waittomove') || 
                      item.file_status === $t('multirecovery.recovfail') || 
                      item.file_status === $t('multirecovery.recovery_cancel') && item.role === 'P'" small>
                        {{ item.file_status }}
                      </v-chip>
                    <v-icon color="green" v-else-if="item.file_status === $t('multirecovery.recovsuccess')"> mdi-check </v-icon>
                    <v-icon color="red" v-else-if="item.file_status === 'duplicate'" @click="open_dialog_manageDup = true"> mdi-content-duplicate </v-icon>
                    <!-- <v-icon color="red" v-else-if="item.file_status === $t('multirecovery.recovfail')"> mdi-close </v-icon> -->
                  </template>
                </v-treeview>
              </v-card-text> 
              <!-- <template> 
                <div class="mt-5">
                  <v-treeview
                  ref="tree"
                  return-object
                  multiple-active
                  class="v-treeview-allfile"
                  :items="group_recovery"
                >
                  <template v-slot:prepend="{item}">
                      <v-icon large :color="item.file_icon[1]">
                        {{ item.file_icon[0] }}
                      </v-icon>
                  </template>
                  <template v-slot:label="{item}">
                    <v-list-item class="ml-n2">
                      <v-list-item-content>
                        <v-list-item-title >
                          <span class="v-treeview-node__label">
                            {{item.file_name | subStr }}
                          </span>
                        </v-list-item-title>
                      </v-list-item-content>
                    </v-list-item>
                  </template>
                  <template v-slot:append="{item}">
                    <v-chip v-if="item.file_status === $t('multirecovery.allowpermission') && item.role === 'P'" small>
                      {{ $t('multirecovery.allowpermission') }}
                    </v-chip>
                    <v-chip v-else-if="item.file_status === $t('multirecovery.notallowpermission') && item.role === 'P'" small>
                      {{ $t('multirecovery.notallowpermission') }}
                    </v-chip>
                    <v-progress-circular
                      v-else-if="item.file_status === $t('multirecovery.recovering')"
                      indeterminate
                      size="20"
                      :color="color.theme"></v-progress-circular>
                      <v-chip v-else-if="item.file_status === $t('multirecovery.waittomove') || item.file_status === $t('multirecovery.recovery_cancel') && item.role === 'P'" small>
                        {{ item.file_status }}
                      </v-chip>
                    <v-icon color="green" v-else-if="item.file_status === $t('multirecovery.recovsuccess')"> mdi-check </v-icon>
                    <v-icon color="red" v-else-if="item.file_status === $t('multirecovery.recovfail')"> mdi-close </v-icon>
                  </template>
                  </v-treeview>
                </div>
              </template>  -->
            </v-tab-item>
          </v-tabs-items>
        </v-card-text>
        <v-divider></v-divider>
        <v-card-actions>
          <!-- <v-layout>
            <v-icon color="#FFCC00">
                mdi-alert-circle
              </v-icon>
            <span>
              {{ $t('multirecovery.selectAll') }}
            </span>
          </v-layout> -->
          <!-- row align-center class="ml-4" v-if="show_all_select && tab === 0"  -->
          <v-flex row align-center class="ml-4" v-if="show_all_select && tab === 0" >
            <v-checkbox v-model="checkAll" class="ma-1 pa-1" hide-details="true" @click="checkAllItem" 
            :disabled="disable_treeview_loading === true" style="font-size: 8px;"></v-checkbox>
            <span>
              {{ $t('multirecovery.selectAll') }}
            </span>
          </v-flex>
          <v-spacer></v-spacer>
          <!-- ปุ่มก่อนกดยืนยันที่จะลบหรือกดปิดdialog -->
          <v-btn
            v-if="check_show_progress === false && check_show_button === false"
            color="red  darken-1 white--text"
            depressed
            @click="$emit('closedialog')"
            >{{ $t("changeName.close") }}</v-btn
          >
          
          <v-btn
            :color="color.theme"
            v-if="check_show_progress === false && check_show_button === false && checkPermissionAll === false"
            :dark="color.darkTheme"
            @click="$router.app['_route']['name'] === 'trashoutbox' || 
            $router.app['_route']['name'] === 'trashinbox' ? fn_multirestore_V2() : summmitrecoveryfile_v5('first'), 
            (check_show_button = true), 
            (check_show_progress = true), 
            (check_restore =true)"
            >{{$t("multirecovery.recovbtn")}}</v-btn
          >
          <!-- <v-btn
            v-show="check_move_dis_button"
            :color="color.theme"
            :dark="color.darkTheme"
            :disabled="multiitemwaittimove.length <= 0"
            @click="movefilefolder_trash" 
            >{{$t("multirecovery.movefilefolder")}}</v-btn
          > -->
          <!-- V2 -->
          <v-btn
            v-if="check_move_dis_button"
            :color="color.theme"
            :dark="disable_treeview_loading === true ? null : color.darkTheme"
            :disabled="disable_treeview_loading === true || multiitemwaitmove_v2.length <= 0 "
            @click="movefilefolder_trash_v2" 
            >{{$t("multirecovery.movefilefolder")}}</v-btn
          >
          <!-- ลบเสร็จจะโชว์ปุ่มนี้เพื่อกดปิด-->
          <!-- <v-btn  color="red  darken-1 white--text" depressed :disabled="check_dis_button" 
          v-if="check_show_button === true" 
           @click="clearalldata">{{$t('dialogmultidelete.buttonclose')}}</v-btn> this.check_dis_button = false;
              this.check_move_dis_button = true -->

            <!-- CANCEL  -->
          <v-btn color="red" depressed :dark="color.darkTheme"
          v-if="check_show_progress === true && check_dis_button === true"
          @click="$router.app['_route']['name'] === 'trashoutbox' || 
            $router.app['_route']['name'] === 'trashinbox' ? cancelRecovery_doc_inoutbox() : cancelRecovery()"> ยกเลิก</v-btn>
           
          <!-- V2 -->
          <v-btn  color="red  darken-1 white--text" depressed :disabled="disable_treeview_loading === true || check_dis_button" 
          v-if="check_show_button === true" 
          @click="clearalldata">{{$t('dialogmultidelete.buttonclose')}}</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>


    <v-dialog v-else v-model="shower" scrollable persistent :max-width="maxWidthOnMobile">
      <v-card class="px-3">
        <v-card-text class="pa-5"  >
          <v-layout xs12 d-flex class="pt-2 pb-1" >
            <v-flex xs10 d-flex justify-center >
              <span :style="headerPage">{{$t("multirecovery.list_filefolder_recov")}}</span>
            </v-flex>
            <v-flex xs2 d-flex justify-end>
              <v-icon
                :color="$vuetify.theme.dark ? 'white' : color.theme"
                @click="clearalldata"
                >mdi-close</v-icon
              >
            </v-flex>
          </v-layout>
        </v-card-text>
        <!-- <v-card-text> -->
          <div class="pb-5"> 
          <v-tabs v-model="tab" height="40">
            <v-tabs-slider :color="color.theme"></v-tabs-slider>
            <v-tab class="text-button" v-show="!disable_tab_nonesuccess" :disabled="disable_tab_nonesuccess">{{ $t("multirecovery.tabnonerecovery_success") }}</v-tab>
            <v-tab  class="text-button"  :disabled="disable_tab_success">{{ $t("multirecovery.tabrecovery_success") }}</v-tab>
            <v-tab class="text-button" > {{ $t("multirecovery.taball") }} ({{ showrestore.length }})</v-tab>
          </v-tabs>
          <v-tabs-items v-model="tab" >
            <v-tab-item >
              <v-list v-if="nonsuccess_loading === true">
                <v-list-item class="pt-4 pb-4">
                  <v-overlay  absolute color="white" dark  >
                    <v-progress-circular
                          indeterminate
                          size="35"
                          :color="color.theme"></v-progress-circular>
                    </v-overlay>
                </v-list-item>
              </v-list>
              <template v-else>
                <div class="pt-3 my-4">
                  <v-treeview
                      ref="tree"
                      selection-type="leaf"
                      return-object
                      multiple-active
                      :items="nonsuccess_group_recovery"
                      class="v-treeview-movefile">
                  <template v-slot:prepend="{item}"  >
                      <v-layout row class="my-n2">
                          <!-- <v-checkbox
                          hide-details="true"
                          class="shrink mt-0"
                          :disabled="disable_treeview_loading === true"
                          v-model="multiitemwaitmove_v2"
                          :value="item"
                          v-if="item.role === 'P' && item.file_status === $t('multirecovery.waittomove')" /> -->
                          <v-icon medium :color="item.file_icon[1]">
                              {{ item.file_icon[0] }}
                          </v-icon>
                      </v-layout>
                  </template>
                  <template v-slot:label="{item}">
                    <!-- <span class="v-treeview-node__label">{{item.file_name | subStr }}</span> -->
                    <v-list-item class="ml-n4">
                      <v-list-item-content>
                        <v-list-item-title >
                          <span class="v-treeview-node__label" style="font-size: 15px;">
                            {{item.file_name | subStr }}
                          </span>
                        </v-list-item-title>
                      </v-list-item-content>
                    </v-list-item>
                  </template>
                   <!-- New V -->
                   <template v-slot:append="{item}">
                    <v-progress-circular
                      v-if="item.file_status === $t('multirecovery.movingfilefolder')"
                      indeterminate
                      size="20"
                      :color="color.theme"></v-progress-circular>
                    <v-icon color="green" v-else-if="item.file_status === $t('multirecovery.recovsuccess')"> mdi-check </v-icon>
                    <v-icon color="red" v-else-if="item.file_status === $t('multirecovery.recovfail')"> mdi-close </v-icon>
                  </template>
              </v-treeview>
                </div>
              </template>
            </v-tab-item>
            <v-tab-item >
                <v-list>
                  <v-list-item v-for="item in success_rocovery" :key="item.file_id" class="pt-3">
                    <v-icon medium :color="item.file_icon[1]">{{ item.file_icon[0]}}</v-icon>
                    <v-list-item-content>
                      <v-list-item-title class="ml-4" style="font-size: 15px;">
                        {{ item.file_name | subStr }}
                      </v-list-item-title>
                      <v-list-item-subtitle class="ml-4" v-if="
                        item.file_status_message === 'This file has been moved' || 
                        item.file_status_message === 'This folder has been moved'">
                        {{$t("multirecovery.filefoldermoved")}}
                      </v-list-item-subtitle>
                    </v-list-item-content>
                    <v-list-item-action>
                      <v-icon
                      color="green" >
                          mdi-check-circle
                      </v-icon>
                    </v-list-item-action>
                  </v-list-item>
                </v-list>
            </v-tab-item>
            <v-tab-item v-if="$router.app['_route']['name'] === 'trashinbox' || $router.app['_route']['name'] === 'trashoutbox'" style="overflow-x: hidden;">
              <v-list>
                <!--โชว์ไฟล์สามารถลบได้ -->
                <v-list-item v-for="item in showrestore" :key="item.file_id">
                  <v-icon large :color="item.file_icon[1]">{{ item.file_icon[0]}}</v-icon>
                  <v-list-item-content>
                    <v-list-item-title class="ml-4 mt-4">
                       <span>
                        {{ item.file_name | subStr }}
                       </span>
                    </v-list-item-title>
                    <v-list-item-subtitle class="ml-4" >
                      <p v-if="item.file_value !== 500" class="font-weight-bold" style="font-size: 13px"
                      :color="
                      item.file_status === $t('multirecovery.recovering') ? color.theme : 
                      item.file_status === $t('multirecovery.recovsuccess') ? 'green' : 
                      item.file_status === $t('multirecovery.recovfail') ? 'red' : 
                      item.file_status === $t('multirecovery.allowpermission')  ? color.theme :'#F37958'"
                      dark >
                        {{ item.file_status }}
                        <!-- {{ item.file_status === $t("multirecovery.recovsuccess") ? '' : item.file_status_message }} -->
                    </p>
                    </v-list-item-subtitle>
                  </v-list-item-content>
                  <v-list-item-action>
                    <v-progress-circular
                          v-if="check_show_progress === true && item.file_value !== 100 && item.file_value !== 500"
                          model-value="16"
                          :value="item.file_value"
                          :color="color.theme"
                          size="28"
                      >
                    </v-progress-circular>
                    <v-icon 
                      color='#F37958'
                      v-if="
                      item.file_status === 'Wait to move' ||
                      item.file_status === 'รอย้ายไฟล์'" >
                          mdi-information
                      </v-icon>
                      <v-icon 
                      color="green"
                      v-if="
                      item.file_status === 'Complete' || 
                      item.file_status === 'กู้คืนไฟล์/โฟลเดอร์สำเร็จ'" >
                          mdi-check-circle
                      </v-icon>
                  </v-list-item-action>
                </v-list-item>
              </v-list>
            </v-tab-item>
            <v-tab-item v-else >
              <template> 
                <div >
                  <v-treeview
                  ref="tree"
                  return-object
                  multiple-active
                  class="v-treeview-allfile"
                  :items="group_recovery"
                >
                  <template v-slot:prepend="{item}">
                      <v-icon medium  :color="item.file_icon[1]" class="ml-n4 mt-3">
                        {{ item.file_icon[0] }}
                      </v-icon>
                  </template>
                  <template v-slot:label="{item}">
                    <v-list-item class="ml-n4 mt-3">
                      <v-list-item-content>
                        <v-list-item-title >
                          <span class="v-treeview-node__label" style="font-size: 15px;">
                            {{item.file_name}}
                            </span>
                        </v-list-item-title>
                        <v-list-item-subtitle class="p-2">
                          <span v-if="item.file_value !== 500" class="font-weight-bold" style="font-size: 13px"
                          :color="
                          item.file_status === $t('multirecovery.recovering') ? color.theme : 
                          item.file_status === $t('multirecovery.recovsuccess') ? 'green' : 
                          item.file_status === $t('multirecovery.recovfail') ? 'red' : 
                          item.file_status === $t('multirecovery.allowpermission')  ? color.theme :'#F37958'"
                          dark >
                            {{ item.file_status === 'duplicate' ? $t('multirecovery.file_duplicate') : item.file_status }}
                          </span>
                        </v-list-item-subtitle>
                      </v-list-item-content>
                      <!--  -->
                    </v-list-item>
                  </template>
                  <template v-slot:append="{item}">
                    <v-progress-circular
                      v-if="item.file_status === $t('multirecovery.recovering')"
                      indeterminate
                      size="14"
                      :color="color.theme"></v-progress-circular>
                    <v-icon color="warning" size="16" v-else-if="item.file_status === $t('multirecovery.waittomove')"> mdi-alert-circle </v-icon>
                    <v-icon color="green" size="16" v-else-if="item.file_status === $t('multirecovery.recovsuccess')"> mdi-check </v-icon>
                    <v-icon color="red" size="16" v-else-if="item.file_status === $t('multirecovery.recovfail')"> mdi-close </v-icon>
                    <v-icon color="red" v-else-if="item.file_status === 'duplicate'" @click="open_dialog_manageDup = true"> mdi-content-duplicate </v-icon>
                  </template>
                  </v-treeview>
                </div>
              </template> 
            </v-tab-item>
          </v-tabs-items>
        </div>
       <div class="text-center mb-4">

        <v-btn
            v-if="check_show_progress === false && check_show_button === false"
            small
            :style="btnAction"
            class="mr-6"
            height="40px"
            width="40%"
            color="red"
            outlined
            @click="$emit('closedialog')"
        >{{ $t("changeName.close") }}</v-btn>

        <v-btn
              v-else-if="check_show_button === true"
              small
              :style="btnAction"
              class="mr-6"
              height="40px"
              width="40%"
              color="red"
              outlined
              :disabled="disable_treeview_loading === true || check_dis_button"
              @click="clearalldata"
        >{{ $t("changeName.close") }}</v-btn>

          <!-- <v-btn
            small
            v-if="tab === 0 && count_waittomove >= 1 && nonsuccess_loading === false"
            class="elevation-0"
            :style="btnAction"
            height="40px"
            width="40%"
            :color="color.theme"
            :dark="multiitemwaittimove.length <= 0 ? null : color.darkTheme"
            :disabled="multiitemwaittimove.length <= 0"
            @click="movefilefolder_trash"
            >{{$t("multirecovery.move")}}</v-btn> -->

            <!-- V2 -->
            <v-btn
            small
            v-if="tab === 0 && count_waittomove >= 1 && nonsuccess_loading === false"
            class="elevation-0"
            :style="btnAction"
            height="px"
            width="40%"
            :color="color.theme"
            :dark="multiitemwaitmove_v2.length <= 0 || disable_treeview_loading === true ? null : color.darkTheme"
            :disabled="disable_treeview_loading === true || multiitemwaitmove_v2.length <= 0"
            @click="movefilefolder_trash_v2"
            >{{$t("multirecovery.move")}}</v-btn>

            <v-btn
            small
            v-else 
            class="elevation-0"
            :style="btnAction"
            height="40px"
            width="40%"
            :color="color.theme"
            :dark="check_show_progress === true ? null : color.darkTheme"
            :disabled="check_show_progress"
            @click="$router.app['_route']['name'] === 'trashoutbox' || 
                    $router.app['_route']['name'] === 'trashinbox' ? 
                    fn_multirestore_V2() : summmitrecoveryfile_v5('first'),
                    (check_show_button = true), 
                    (check_show_progress = true), 
                    (check_restore =true)"
            >{{$t("multirecovery.recovbtn")}}</v-btn>
       </div>
      </v-card>
    </v-dialog>

    <v-dialog v-model="open_dialog_manageDup"  persistent  scrollable max-width="750" >
      <v-card>
        <v-card-title>
          Manage Duplicate
          <v-spacer></v-spacer>
          <v-icon :color="color.theme" dense dark size="35" class="mr-3">restore</v-icon>
          <!-- <v-chip  class="pt-4 pl-2 ma-2"><p ><v-icon :color="color.theme"> folder </v-icon><b> {{ count_folder }} {{$t("multirecovery.folder")}}</b></p></v-chip>
          <v-chip   class="pt-4 pl-2 ma-2"><p ><v-icon :color="color.theme">mdi-file</v-icon><b> {{ count_file }} {{$t("multirecovery.file")}}</b></p></v-chip>  -->
        </v-card-title>
        <v-divider></v-divider>
        <v-card-text>
          <v-card-text ref="scrollCardAll"> 
                <v-treeview
                  ref="tree"
                  return-object
                  multiple-active
                  class="v-treeview-allfile"
                  :items="duplicate_file_recovery"
                >
                  <template v-slot:prepend="{item}">
                      <v-icon v-if="resolutionScreen >= 500" large  :color="item.file_icon[1]">
                        {{ item.file_icon[0] }}
                      </v-icon>
                      <v-icon v-else  class="ml-n6" :color="item.file_icon[1]">
                        {{ item.file_icon[0] }}
                      </v-icon>
                  </template>
                  <template v-slot:label="{item}">
                    <v-list-item class="ml-n2">
                      <v-list-item-content>
                        <v-list-item-title v-if="!item.status_change_name || item.status_change_name === undefined" >
                          <span class="v-treeview-node__label">
                            {{item.file_name | subStr }}
                          </span>
                        </v-list-item-title>
                        <!-- <v-list-item-title v-if="true" >
                          <span class="v-treeview-node__label">
                            {{item.file_name | subStr }}
                          </span>
                        </v-list-item-title> -->
                        <v-text-field
                          v-else 
                          outlined
                          dense
                          v-model="item.namemodel"
                          :rules="[requiredRule, specialCharRule]"
                          :validate-on-blur="false" 
                          :lazy-rules="false"
                          :error-messages="item.errorMessage"
                          @input="validateField(item)"
                        >

                        </v-text-field>
                      </v-list-item-content>
                    </v-list-item>
                  </template>
                  <template v-slot:append="{item}">
                    <!-- <div v-if="item.type === 'file'" >
                      <v-chip small v-if="item.role === 'P' && !item.restore_copy" class="mr-3" @click="fn_manageDup(item, 'copy')">
                     คัดลอก
                    </v-chip>
                    <v-chip v-if="!item.status_change_name && item.role === 'P' && !item.restore_copy" small @click="fn_manageDup(item, 'change')">
                      เปลี่ยนชื่อ
                    </v-chip>
                    </div>
                    <div v-else>
                      <v-chip small v-if="item.role === 'P' && !item.restore_copy" class="mr-3" @click="fn_manageDup(item, 'copy')">
                     คัดลอก
                    </v-chip>
                    <v-chip v-if="!item.status_change_name && item.role === 'P' && !item.restore_copy" small @click="fn_manageDup(item, 'change')">
                      เปลี่ยนชื่อ
                    </v-chip>
                    </div> -->
                    <v-tooltip top v-if="item.role === 'P' && !item.state_change" class="mr-3">
                      <template v-slot:activator="{ on, attrs }">
                        <v-icon
                          @click="fn_manageDup(item, 'copy')"
                          color="primary"
                          dark
                          v-bind="attrs"
                          v-on="on"
                          class="ml-sm-6"
                        >
                        mdi-content-copy
                        </v-icon>
                      </template>
                      <span>คัดลอกไฟล์</span>
                    </v-tooltip>

                    <v-tooltip top v-if="!item.status_change_name && item.role === 'P' && !item.state_change">
                      <template v-slot:activator="{ on, attrs }">
                        <v-icon
                          @click="fn_manageDup(item, 'change')"
                          color="primary"
                          dark
                          v-bind="attrs"
                          v-on="on"
                          class="ml-3"
                        >
                        mdi-pencil-box-outline
                        </v-icon>
                      </template>
                      <span>เปลี่ยนชื่อ</span>
                    </v-tooltip>

                    <!-- <v-icon v-if="item.role === 'P' && !item.state_change" class="mr-3" @click="fn_manageDup(item, 'copy')" >
                      mdi-content-copy
                    </v-icon>
                    <v-icon v-if="!item.status_change_name && item.role === 'P' && !item.state_change"  @click="fn_manageDup(item, 'change')" >
                      mdi-pencil-box-outline
                    </v-icon> -->
                    <!-- <v-chip v-if="!item.status_change_name && item.role === 'P' && !item.state_change" small @click="fn_manageDup(item, 'change')">
                      เปลี่ยนชื่อ
                    </v-chip> -->
                    <!-- <v-chip class="ml-3" v-else-if="item.status_change_name && item.role === 'P' && !item.state_change" small @click="fn_changeName(item)" 
                    :disabled="(`${item.namemodel}.${item.file_type}` === item.backup_name) || item.namemodel === item.backup_name || item.namemodel === '' || item.errorMessage !== ''">
                      ยืนยัน
                    </v-chip> -->

                    <v-tooltip top v-else-if="item.status_change_name && item.role === 'P' && !item.state_change" small>
                      <template v-slot:activator="{ on, attrs }">
                        <v-icon
                          @click="fn_changeName(item)"
                          color="success"
                          v-bind="attrs"
                          v-on="on"
                          class="ml-3"
                          :disabled="(`${item.namemodel}.${item.file_type}` === item.backup_name) || item.namemodel === item.backup_name || item.namemodel === '' || item.errorMessage !== ''"
                        >
                        mdi-check-bold
                        </v-icon>
                      </template>
                      <span>ยืนยัน</span>
                    </v-tooltip>

                    <span v-if="item.restore_copy && item.state_change" >
                      (คัดลอก)
                    </span>
                    <span v-else-if="!item.restore_copy && !item.status_change_name && item.state_change" >
                      (เปลี่ยนชื่อ)
                    </span>
                    <!-- <v-icon color="red" v-else-if="item.file_status === $t('multirecovery.recovfail')"> mdi-close </v-icon> -->
                  </template>
                </v-treeview>
              </v-card-text>
        </v-card-text>
        <v-divider></v-divider>
        <v-card-actions v-if="resolutionScreen >= 500">
          <v-spacer></v-spacer>
          <!-- ปุ่มก่อนกดยืนยันที่จะลบหรือกดปิดdialog -->
          <v-btn
            color="red  darken-1 white--text"
            depressed
            @click="fn_closeDialogManageDup()"
            >{{ $t("changeName.close") }}</v-btn
          >
          <v-btn
            color="red  darken-1 white--text"
            depressed
            @click="fn_backupAll()"
            >{{ $t('default') === 'en' ? 'Backup' : 'ย้อนกลับ' }}</v-btn
          >
          <v-btn
            class="white--text darken-1"
            :color="`${color.theme}`"
            depressed
            @click="fn_copyAll()"
            >{{ $t('default') === 'en' ? 'All Copy' : 'คัดลอกทั้งหมด' }}</v-btn
          >
          <v-btn
            class="white--text darken-1"
            :color="`${color.theme}`"
            :dark="disabledRestore.length >= 1 ? false : true"
            :disabled="disabledRestore.length >= 1"
            @click="fn_restoreAll()"
            >{{$t("multirecovery.recovbtn")}}</v-btn
          >
          
        </v-card-actions>
        <v-card-actions v-else>
          <v-spacer></v-spacer>
          <!-- ปุ่มก่อนกดยืนยันที่จะลบหรือกดปิดdialog -->
          <v-btn
            small
            color="red  darken-1 white--text"
            depressed
            @click="fn_closeDialogManageDup()"
            >{{ $t("changeName.close") }}</v-btn
          >
          <v-btn
            small
            class="white--text darken-1"
            :color="color.theme"
            depressed
            @click="fn_backupAll()"
            >{{ $t('default') === 'en' ? 'Backup' : 'ย้อนกลับ' }}</v-btn
          >
          <v-btn
            small
            class="white--text darken-1"
            :color="color.theme"
            depressed
            @click="fn_copyAll()"
            >{{ $t('default') === 'en' ? 'All Copy' : 'คัดลอกทั้งหมด' }}</v-btn
          >
          <v-btn
            class="white--text darken-1"
            small
            :color="color.theme"
            :dark="disabledRestore.length >= 1 ? false : true"
            :disabled="disabledRestore.length >= 1"
            @click="fn_restoreAll()"
            >{{$t("multirecovery.recovbtn")}}</v-btn
          >
          
        </v-card-actions>
      </v-card>
    </v-dialog>
  </v-row>
</template>
<script>
import { mapState, mapGetters } from "vuex";
import Swal from "sweetalert2/dist/sweetalert2.js";
import "sweetalert2/src/sweetalert2.scss";
import { validationMixin } from "vuelidate";
import { required, maxLength, email } from "vuelidate/lib/validators";
import gbfGenerate from "@/globalFunctions/generateAuthorize";
import  { CancelToken } from 'axios';
export default {
  mixins: [validationMixin],
  validations: {
    filename: { required },
  },
  props: ["show", "file", "filefoldersuccess", "filefolderupdate", "filefoldermoveto"], // Add "filefoldersuccess"
  data: function() {
    return {
      check_restore:false,
      check_dis_button:true,
      check_show_progress: false,
      check_show_button: false,
      showrestore: [],
      // showcannotdelete:[],
      count_item: 0,
      count_folder:0,
      count_file:0,
      list_folder:[],
      list_file:[],
      countshowrestore:0,
      count_restoring:0,
      count_duplicate: 0,
      // countshowcannotdelete:0

      //new variables
      heightGroupItem: 0,
      cancelCheck: false,
      cancelCheck_doc: false,
      cancelSource: "", // New Variable
      cancelSource_doc: "",
      tab: null,
      treeviewfile: false,
      openIds: [],
      tempOpenIds: [],
      success_rocovery: [],
      nonesuccess_recovery: [],
      group_recovery: [],
      nonsuccess_group_recovery: [],
      multiitemwaittimove: [],
      multiitemwaitmove_v2: [],
      selectionWithParents: [],
      newsortfilefolder: [],
      check_move_dis_button: false,
      checkPermissionAll: false,
      show_all_select: false,
      checkAll: false,
      nonsuccess_loading: true,
      disable_tab_success: true,
      disable_tab_nonesuccess: true,
      disable_treeview_loading: false,
      count_waittomove: 0,
      count_restoreerror: 0,
      check_cancel: false,
      duplicate_file_recovery: [],
      open_dialog_manageDup: false,
      rules: {
        noSpecialChar: (name) =>
          this.fn_checkSpecialChar(name) ||
          // "A folder name cannot contain any of the following characters: \ / < > : ? * |",
          this.$t("uploadfolder.nospecialcharacter")
      },

    };
  },
  computed: {
    ...mapState(["username", "authorize", "account_active", "color"]),
    ...mapState({ processloader: "loading" }),
    ...mapGetters([
      "dataUsername",
      "dataAuthorize",
      "dataAccountActive",
      "dataAccesstoken",
      "dataBusinessProfile",
      "dataCitizenProfile",
      "dataDepartmentAccessId",
      "dataAccountId",
      "dataColor"
    ]),
    resolutionScreen() {
      switch (this.$vuetify.breakpoint.name) {
        case "xs":
          return 220;
        case "sm":
          return 400;
        case "md":
          return 500;
        case "lg":
          return 600;
        case "xl":
          return 800;
      }
    },
    subtitle () {
      return "font-size: 14px"
    },
    // style อักษร header ของ mobile
    headerPage() {
      return (
        "color:" +
        this.color.theme +
        ";" +
        `font-size: ${window.innerWidth >= 330 ? '16px' : '13px' }; font-weight: 600; font-weight: bold; letter-spacing: 0px;`
      );
    },
    widthDialogMobile () {
      return window.innerWidth >= 600 ? '500px' : '700px'
    },
    // style อักษรใน dialog ของ mobile
    titleText() {
      return "color:black; font-size: 14px; line-height: 24px;";
    },
    // style ปุ่ม ของ mobile
    btnAction() {
      return "font-size: 16px; font-weight: lighter;";
    },
    // ความกว้างของ dialog ของ ipad และ mobile
    maxWidthOnMobile() {
      if(this.resolutionScreen >= 400) {
        return 600
      } else {
        return 400
      }
    },
    shower: {
      get() {
        if (this.show === true) {
          console.log("file",this.file);
          //Add 
          this.checkAll = false
          this.show_all_select = false
          /////
        }
        return this.show;
      },
      set(value) {
        if (!value) {
        }
      },
    },
    requiredRule() {
      return v => (!!v ? true : 'This field is required');
    },
    specialCharRule() {
      return v =>
        /^[^<>:?*\\|"/]*$/.test(v) ? true : 'Special characters */- are not allowed';
    },
    disabledRestore () {
      console.log("WTFF ", this.duplicate_file_recovery.map((item) => item.errorMessage))
      return this.duplicate_file_recovery.map((item) => { return item.errorMessage}).filter((itemFilter) => itemFilter !== "")
      .concat(this.duplicate_file_recovery.map((item) => { return item.status_change_name}).filter((itemFilter) => itemFilter === true))
      .concat(this.duplicate_file_recovery.map((item) => { return item.state_change}).filter((itemFilter) => itemFilter === false))
    }

  },

  filters: { //Add (Mai)
    subStr: function(string) {
      if (string == null || string == "" || string == undefined) {
        return string;
      } else {
        if (string.length > 35) return string.substring(0, 35) + "...";
        else return string;
      }
    },
  },

  watch: {
    show: function(val) {
      if (val === true) {
        this.$emit("closeDrag");
        //this.fn_loaddata(); then
        this.fn_loaddata_v2(); // now
        this.check_show_button = false;
        setTimeout(() => {
          console.log("Working thiss ")
          this.scrollToTop()
          },200)
        
      } else {
        this.$emit("openDrag"); 
        //this.check_show_progress = false; then
        setTimeout(() => {
          this.showrestore = [];
          this.showcannotdelete = [];
          this.count_item = 0;
          this.count_folder = 0;
          this.count_file = 0;
        }, 200)
      }
    },
    //Add Mai
    multiitemwaittimove (newVal, oldVal) {
      if(newVal.length <= 0){
        this.checkAll = false
      }else{
        let _selectedNodes = []
        let _treenodes = this.$refs['tree'].nodes
        for (const key in _treenodes) {
          if (Object.hasOwnProperty.call(_treenodes, key)) { //เช็คว่า id property นี้มียุใย treenode มั้ย
            const node = _treenodes[key]; //เอาตัว obj ของ treenode ตาม index key
            if (node.isSelected){ // เช็คว่า property selected  obj ตัวนี้ถูกเลือกหรือป่าว 
              _selectedNodes.push(node.item)
            }
          }
        }
        this.selectionWithParents = _selectedNodes;

        //sort by trash_dtm and folder 
        if(this.selectionWithParents.length === this.showrestore.filter((item) => item.file_status === 'Wait to move' || item.file_status === 'รอย้ายไฟล์').length){
            this.checkAll =  true
        }
        else{
            this.checkAll = false
        }
      }
      
    },
    // V 2 
    multiitemwaitmove_v2 (newVal, oldVal) {
      if(newVal.length <= 0 || newVal.length < this.nonsuccess_group_recovery.length){
        this.checkAll = false
      } else {
        this.checkAll = true
      }
    },
    filefoldersuccess: function (val) { // Use on Inbox and outbox 
      console.log("Stilll working hereee ")
      if(val.length > 0){
        let nonrecovery_success = this.nonesuccess_recovery.map(obj => obj.file_id)
        let recovery_sucess = val.filter((item) => item.file_status === this.$t('multirecovery.recovsuccess')).map(object => object.file_id)
        let finall_nonreovery_success = []
        nonrecovery_success = nonrecovery_success.filter(val => !recovery_sucess.includes(val));
        for(var i = 0; i < nonrecovery_success.length; i++){ // [1, 2, 3, 4] recove [5, 6]
            finall_nonreovery_success.push(this.nonesuccess_recovery[this.nonesuccess_recovery.findIndex((item) => item.file_id === nonrecovery_success[i])])
        }
        for(var j = 0; j < recovery_sucess.length; j++){
            this.success_rocovery.push(this.nonesuccess_recovery[this.nonesuccess_recovery.findIndex((item) => item.file_id === recovery_sucess[j])])
        }

        this.disable_tab_success = this.success_rocovery.length > 0 ? false : true
        this.checkAll = false 
        this.multiitemwaittimove = []
        if(this.showrestore.filter((item) => item.file_status === 'Wait to move' || item.file_status === 'รอย้ายไฟล์').length > 0){
          console.log("Why not here in multirestore 0")
          this.nonesuccess_recovery = finall_nonreovery_success
          this.checkgroupchildparent()
        }else{
          console.log("Why not here in multirestore 1")
            this.tab = 1
            this.disable_tab_nonesuccess = true
            this.show_all_select = false
        }
      }
      
    },
    tab (val) {
      console.log("Val Tab ", val)
      if(val === 1){
        if(this.$router.app["_route"]["name"] === "trashoutbox" || this.$router.app["_route"]["name"] === "trashinbox"){
          return
        }
        if(this.resolutionScreen < 500){
          return
        }

        setTimeout(() => {
            this.scrollToTop()
          },200)
        // Sort file and folder in success tab
        let newSortFolder_success = []
        let newSortFile_success = []
        newSortFolder_success = this.success_rocovery.filter((item) => item.file_type === "folder").sort(function(a, b) {
              return a.trash_dtm.localeCompare(b.trash_dtm);
        }).reverse()
        newSortFile_success = this.success_rocovery.filter((item) => item.file_type !== "folder").sort(function(a, b) {
              return a.trash_dtm.localeCompare(b.trash_dtm);
        }).reverse()
        this.success_rocovery = [...newSortFolder_success, ...newSortFile_success]

      } else if (val === 2) {
       
        // setTimeout(() => {
        //   console.log("Working thiss ")
        //   this.scrollToTop()
        //   },200)
        
      } else if (val === 0) {
        if(this.resolutionScreen < 500){
          this.multiitemwaitmove_v2.push(this.nonsuccess_group_recovery[0])
          return
        }

        if(this.nonsuccess_group_recovery.length > 0 && this.nonsuccess_group_recovery.filter((item)=> item.file_status ===  this.$t('multirecovery.recovery_cancel')).length <= 0 ){
          this.show_all_select = true
          setTimeout(() => {
            this.scrollToTop()
          },200)
          // if(this.nonsuccess_loading === false){
          // console.log("Array fomr ", )
          // }
        } 
      }
    },
    filefoldermoveto: function (val) {
      if(val !== "" && val !== undefined  && val !== null){
        //this.summitmovefile()
        console.log("Call Again ", val)
        // TEST DEE
        this.summitmovefile2()
      }
    }

  },
  methods: {
    fn_copyAll () {
      this.duplicate_file_recovery.map((item) => {
        item['restore_copy'] = true
        item['state_change'] = true
        item['status_change_name'] = false
        item['namemodel'] = ""
      })
    },
    async fn_restoreAll () {
      this.open_dialog_manageDup = false
      console.log('duplcaiteee ', this.duplicate_file_recovery, this.group_recovery)
      // เปิด tab ที่ 2 พร้อมกับต้อง disabled ทุกอย่างง
      this.tab = 2
      


      // ทำการสร้าง map ของ item duplicate มาก่อน
      const MapFileDuplicate = new Map(this.duplicate_file_recovery.map(obj => [obj.file_id, obj]));

      // เพิ่ม key และ value จาก B ไปยัง A
      let prepare_combined_item = this.group_recovery.map(obj => {
        const match = MapFileDuplicate.get(obj.file_id);
        if (match) {
          return { ...obj, ...match }; // รวม property ของ obj และ match
        }
        return obj; // ใช้ค่าเดิมถ้าไม่มี match
      });
      
      prepare_combined_item.map((itemMap) => {
        if (itemMap.state_change !== undefined) {
          itemMap['file_name'] = itemMap['type'] === 'folder' ? itemMap['namemodel'] : `${itemMap['namemodel']}.${itemMap['file_type']}`
        }
      })

      this.group_recovery = [...prepare_combined_item]

      let preparep_file_dup = [...this.group_recovery.map((item) => { return item })]
      preparep_file_dup = preparep_file_dup.filter((item) => item.file_status === 'duplicate' )
      preparep_file_dup = await this.file_id_chunk(preparep_file_dup, 5)

      // เป็นการวนยิง check duplicate และ restore อีกครั้งนึง
      for (var i = 0; i < preparep_file_dup.length; i++) {
        const promises = await preparep_file_dup[i].map(async item => {
            console.log("griupp recovery ", this.group_recovery, item)
            let findIndex = this.group_recovery.findIndex((itemFind) => itemFind.file_id === item.file_id)  
            this.group_recovery[findIndex]["file_status"] = this.$t("multirecovery.recovering");
            console.log("griupp recovery ", this.group_recovery, item, findIndex)
            // ## เก็บ file_id ของโฟลเดอรที่มีลูก
            var finally_sub_fileId
                let temp_folder = []
                let temp_file = []
                finally_sub_fileId = this.depressed_subfilefolder_id(
                item.file_type === 'folder' ? item.children : [], temp_folder, temp_file)
            
           let payload = {
                account_id: this.file.find((itemFind) => itemFind.file_id === item.file_id).account_sender,
                user_id: this.dataUsername,
                data_id: item.file_id,
                data_type: item.file_type === "folder" ? "folder" : "file",
                sub_folder: finally_sub_fileId.tempfolder,
                sub_file: finally_sub_fileId.tempfile,
                duplicate_status: true,
                new_name: !item.restore_copy ? item.type === 'folder' ? item.namemodel : `${item.namemodel}.${item.file_type}` : ""
              }

            const responseRecovery =  await this.fn_restore_filefolder_v3 (item, payload)
            return responseRecovery
          })
          await Promise.all(promises)
          .then(async (response) => {
               for(const itemFile of response){
                if(itemFile.status === 'OK'){
                  this.count_restoring ++
                  this.success_rocovery.push(this.group_recovery.find((item) => item.file_id === itemFile.file_id ))
                  if(itemFile.file_type === 'folder'){ // #อัพเดต ไฟล์หรือโฟลเดอร์ตัวลูกๆ
                    let countmistake = 0
                    //await this.updatefilestatus(itemFile.children, itemFile.data_miss, countmistake)

                    //เพิ่ม snipped
                    if(await this.updatefilestatus(itemFile.children, itemFile.data_miss, countmistake) > 0){ // ## เช็ค datamiss ที่ส่งมาบาง fileid ของลูกๆนั้น
                        let findIndexItem = this.group_recovery.findIndex((item) => item.file_id === itemFile.file_id)
                        this.group_recovery[findIndexItem]["message"] = 'บางไฟล์/โฟลเดอร์กู้คืนไม่สำเร็จ'
                      }
                  }
                } 
                else if (itemFile.status === 'WAIT TO MOVE') {
                  try{
                    this.count_waittomove++
                    let findIndexError = this.group_recovery.findIndex((item) => item.file_id === itemFile.file_id)
                    this.group_recovery[findIndexError]["file_status"] = this.$t("multirecovery.waittomove");

                    // ## เก็บ file หรือ folder แบบ treeview และอัพเดตตัวลูก (แบบใหม่)
                    if(this.group_recovery[findIndexError] !== 'folder'){
                      // this.nonesuccess_recovery.push(this.group_recovery[i]) // Old
                      this.nonsuccess_group_recovery.push(this.group_recovery[findIndexError])
                    } else{
                      await this.updatefilestatus_waittomove(this.group_recovery[findIndexError].children)
                      this.nonsuccess_group_recovery.push(this.group_recovery[findIndexError])
                    }
                  } catch (err) {
                    console.log("ERRORR ", err)
                  }

                } 
                else if (itemFile.status === 'FILE/FOLDER ERROR') {
                    let findIndexError = this.group_recovery.findIndex((item) => item.file_id === itemFile.file_id)
                    this.group_recovery[findIndexError]["file_status"] = this.$t("multirecovery.recovfail");
                    if(this.group_recovery[i].file_type === 'folder'){
                      await this.updatefilestatus_failmove(itemFile.children)
                    }
                } 
                else {
                  check_error_round = true
                  file_id_mistake = itemFile
                }
              }
          })

          if(preparep_file_dup.length - 1 === i){
              this.check_dis_button = false;
              this.check_move_dis_button = true
              await this.timeout(1000)
              // ## มีไอเทมสำเร็จและรอย้าย (เปิดกู้ทั้งหมด - เปิดรอย้าย)
              if(this.count_waittomove > 0 && this.count_restoring > 0 ){
                      this.tab = 0
                      this.show_all_select = true
                      this.disable_tab_nonesuccess = false
                      this.disable_tab_success = false
                      this.checkgroup()
              }
              // ## ไม่มีไอเทมที่สำเร็จ (ปิดกู้ทั้งหมด - เปิดรอย้าย)
              else if (this.count_waittomove > 0 && this.count_restoring <= 0){
                      this.tab = 0
                      this.show_all_select = true
                      this.disable_tab_nonesuccess = false
                      this.checkgroup()
              }
              // ## ไม่มีไอเทมรอย้าย (เปิดกู้ทั้งหมด - ปิดรอย้าย)
              else if (this.count_restoring > 0 || this.count_restoreerror > 0 && this.count_waittomove <= 0  ) {
                      this.tab = 1
                      this.show_all_select = false
                      this.disable_tab_success = false
              }

        }
      }
      console.log("prepare_combined_item ", preparep_file_dup)
    
      // ทำการ recovery ของที่เป็น duplicate ใหม่
      


    },
    fn_closeDialogManageDup () {
      // this.duplicate_file_recovery.map((item) => {
      //   item['file_name'] = item.backup_name
      //   item['namemodel'] = item.backup_name.replace(/\.[^/.]+$/, '')
      //   item['status_change_name'] = false
      //   item['errorMessage'] = ""
      //   item['restore_copy'] = false
      // })
      this.open_dialog_manageDup = false
      console.log("duplicate filee ", this.duplicate_file_recovery, this.group_recovery)

    },
    fn_backupAll () {
      this.duplicate_file_recovery.map((item) => {
        item['file_name'] = item.backup_name
        item['namemodel'] = item.type === 'folder' ? item.backup_name : item.backup_name.replace(/\.[^/.]+$/, '')
        item['status_change_name'] = false
        item['errorMessage'] = ""
        item['restore_copy'] = false
        item['state_change'] = false

      })
    },
    fn_changeName (itemFile) {
      let findIndex = this.duplicate_file_recovery.findIndex((item) => item.file_id === itemFile.file_id)
      if (this.duplicate_file_recovery[findIndex].namemodel !== this.duplicate_file_recovery[findIndex].type === 'folder' ? this.duplicate_file_recovery[findIndex].backup_name : this.duplicate_file_recovery[findIndex].backup_name.replace(/\.[^/.]+$/, '')) {
        this.duplicate_file_recovery[findIndex].file_name = this.duplicate_file_recovery[findIndex].type === 'folder' ? `${this.duplicate_file_recovery[findIndex].namemodel}` : `${this.duplicate_file_recovery[findIndex].namemodel}.${this.duplicate_file_recovery[findIndex].file_type}`
        this.duplicate_file_recovery[findIndex].status_change_name = false
        
      } else {
        this.duplicate_file_recovery[findIndex].status_change_name = false
      }
      this.duplicate_file_recovery[findIndex].restore_copy = false
      this.duplicate_file_recovery[findIndex].state_change = true


      
    },
    validateField(itemFile) {
      let findIndex = this.duplicate_file_recovery.findIndex((item) => item.file_id === itemFile.file_id)
      const field = this.duplicate_file_recovery[findIndex];
      const rules = [this.requiredRule, this.specialCharRule];
      for (let rule of rules) {
        const result = rule(field.namemodel);
        if (result !== true) {
          field.errorMessage = result; // แสดงข้อความ error
          return;
        }
      }
      field.errorMessage = ''; // ถ้าผ่านทั้งหมดให้ล้าง error message
    },
    fn_checkSpecialChar(filename) {
      let allow = true;
      let specialChar = ["<", ">", ":", "*", "?", "\\", "|", "/", '"'];

      for (let index = 0; index < specialChar.length; index++) {
        const element = specialChar[index];
        if (filename.includes(element)) {
          allow = false;
          break;
        }
      }
      // console.log("allow", allow);
      return allow;
    },
    fn_manageDup (itemFile, type) {
      let findIndex = this.duplicate_file_recovery.findIndex((item) => item.file_id === itemFile.file_id)
      if (type === 'change') {
        console.log("Index of Item ", findIndex);
        let prepare_item = [...this.duplicate_file_recovery]
        prepare_item[findIndex].status_change_name = true
        this.duplicate_file_recovery = [...prepare_item]
      } else {
        if (![undefined].includes(this.duplicate_file_recovery[findIndex].status_change_name)) {
          this.duplicate_file_recovery[findIndex].restore_copy = true
          this.duplicate_file_recovery[findIndex].status_change_name = false
          this.duplicate_file_recovery[findIndex].file_name = this.duplicate_file_recovery[findIndex].backup_name
          this.duplicate_file_recovery[findIndex].namemodel = this.duplicate_file_recovery[findIndex].type === 'folder' ? this.duplicate_file_recovery[findIndex].backup_name :  this.duplicate_file_recovery[findIndex].backup_name.replace(/\.[^/.]+$/, '')
          this.duplicate_file_recovery[findIndex].errorMessage = ""
          this.duplicate_file_recovery[findIndex].state_change = true
        }
      }
    },
    scrollToTop () {
      if(this.resolutionScreen >= 500){
        this.$refs['scrollCard'].scrollTo({top: 0})
      }
      //Array.from(document.getElementsByClassName('v-treeview-movefile')[0].children)[0].scrollTo({top: 0, behavior: 'smooth'})
    },
    /// Add new
    checkAllItem () {
      if(this.checkAll){
        this.multiitemwaitmove_v2 = this.nonsuccess_group_recovery.filter((item) => item.file_status === this.$t("multirecovery.waittomove"))
        console.log("Multimove wait true ", this.multiitemwaitmove_v2)
      }else {
        this.multiitemwaitmove_v2 = []
        console.log("Multimove wait falsee ", this.multiitemwaitmove_v2)
      }
    },
    timeout(ms) {
      return new Promise(resolve => setTimeout(resolve, ms));
    },
    fn_loaddata_v2() {      
      for (let i = 0; i < this.file.length; i++) {
        this.count_item++;
        if(this.file[i].file_type === 'folder'){
          this.count_folder++;
          // this.list_folder.push(this.file[i])
          // this.count_folder = this.list_folder.length;
        }else{
          this.count_file++;
          // this.list_file.push(this.file[i])
          // this.count_file = this.list_file.length;
        }
        if(this.$router.app["_route"]["name"] === "trashinbox" || this.$router.app["_route"]["name"] === "trashoutbox"){
        console.log("++++",this.file[i]);
        let datarestore = {};
        datarestore["file_name"] = this.file[i]["file_name"];
        datarestore["file_id"] = this.file[i]["file_id"];
        datarestore["file_icon"] = this.file[i]["file_icon"];
        datarestore["file_status"] =  this.$t('multirecovery.document_allowpermission');
        // datarestore["file_status"] = "ต้องการกู้คืน";
        datarestore["file_value"] = 0;
        datarestore["show_name"] = false;
        datarestore["file_type"] = this.file[i]["file_type"];
          this.showrestore.push(datarestore);
        }else{
          console.log("++++",this.file[i]);
        let datarestore = {};
        datarestore["file_name"] = this.file[i]["file_name"];
        datarestore["file_id"] = this.file[i]["file_id"];
        datarestore["file_icon"] = this.file[i]["file_icon"];
        datarestore["file_status"] = this.file[i]["permission_account_v2"] === '05' || this.file[i]["permission_account_v2"] === '06' ? this.$t("multirecovery.allowpermission") : this.$t("multirecovery.notallowpermission") ;
        datarestore["file_status_message"] = ""
        datarestore["file_status_pregress"] =""
        datarestore["file_permission"] = this.file[i]["permission_account_v2"];
        datarestore["trash_dtm"] = this.file[i]["trash_dtm"] 
        datarestore["file_value"] = 0;
        datarestore["show_name"] = false;
        datarestore["file_type"] = this.file[i]["file_type"];
        datarestore["file_createdtm"] = this.file[i]["file_createdtm"] 
        datarestore["full_path"] = this.file[i]["full_path"]
        datarestore["full_path_id"] = this.file[i]["full_path_id"]
        datarestore["folder_id"] = this.file[i]["folder_id"]
        datarestore["type"] = this.file[i]["type"]
          this.showrestore.push(datarestore);
        }
      }
      //Sort by folder and trash_dtm 
      if(this.$router.app["_route"]["name"] !== 'trashinbox' && this.$router.app["_route"]["name"] !== "trashoutbox" ){
        this.newsortfilefolder = this.showrestore.filter((item) => item.file_type === "folder")
        this.newsortfilefolder = this.newsortfilefolder.sort(function(a, b) {
              return a.trash_dtm.localeCompare(b.trash_dtm);
        }).reverse()
        this.showrestore = this.newsortfilefolder.concat(this.showrestore.filter((item) => item.file_type !== "folder"))
        // if(this.file.map((item) => item.permission_account_v2).filter((permis) => permis  === '05' || permis === '06').length > 0){
        // this.checkPermissionAll = false
        // }
        // else {
        //   this.checkPermissionAll = true
        // }
        //this.changeTreeview()
        //this.changeTreeview_v2()
        this.changeTreeview_v3()
      }
      
     
      
      this.countshowrestore = this.showrestore.length;
      this.count_restoring = 0;
     
  },
  
    fn_loaddata() {    
        
        for (let i = 0; i < this.file.length; i++) {
        this.count_item++;
        console.log(this.file);
        if(this.file[i].file_type === 'folder'){
          this.count_folder++;
          // this.list_folder.push(this.file[i])
          // this.count_folder = this.list_folder.length;
        }else{
          this.count_file++;
          // this.list_file.push(this.file[i])
          // this.count_file = this.list_file.length;
        }
        if(this.$router.app["_route"]["name"] === "trashinbox" || this.$router.app["_route"]["name"] === "trashoutbox"){
        console.log("++++",this.file[i]);
        let datarestore = {};
        datarestore["file_name"] = this.file[i]["file_name"];
        datarestore["file_id"] = this.file[i]["file_id"];
        datarestore["file_icon"] = this.file[i]["file_icon"];
        datarestore["file_status"] = 'มีสิทธิ์กู้คืนไฟล์';
        // datarestore["file_status"] = "ต้องการกู้คืน";
        datarestore["file_value"] = 0;
        datarestore["show_name"] = false;
        datarestore["file_type"] = this.file[i]["file_type"];
          this.showrestore.push(datarestore);
        }else{
          console.log("++++",this.file[i]);
        let datarestore = {};
        datarestore["file_name"] = this.file[i]["file_name"];
        datarestore["file_id"] = this.file[i]["file_id"];
        datarestore["file_icon"] = this.file[i]["file_icon"];
        datarestore["file_status"] = this.file[i]["permission_account_v2"] === '05' || this.file[i]["permission_account_v2"] === '06' ? 'มีสิทธิ์กู้คืนไฟล์' : 'ไม่มีสิทธิ์กู้คืนไฟล์' ;
        // datarestore["file_status"] = "ต้องการกู้คืน";
        datarestore["file_value"] = 0;
        datarestore["show_name"] = false;
        datarestore["file_type"] = this.file[i]["file_type"];
          this.showrestore.push(datarestore);
        }
      }
      this.countshowrestore = this.showrestore.length;
      this.count_restoring = 0;
      // console.log("showrestore",this.showrestore);
    },


    // ## ใช้งานบน Mobile และ Inbox Outbox dialog
    async fn_multirestore_V2() { 
      if(this.$router.app["_route"]["name"] === "trashinbox"){
        this.cancelSource_doc = CancelToken.source()
        for (let i = 0; i < this.file.length; i++) {
          let check_cancel
          let objIndex = this.showrestore.findIndex((obj) => obj.file_id === this.file[i].file_id);
          //เช็คเพื่อนวนส่งข้อมูล
            this.showrestore[objIndex]["file_status"] = this.$t("multirecovery.document_recovering"); //document_recovering
            let payload = {
                business_id : this.dataAccountActive.business_info.business_id,
                data_type : "inbox",
                inbox_id: [this.file[i]["inbox_id"]]
            };
            // console.log("payload",payload);
            await this.fn_restore_v2(payload, this.file[i].file_id)
            .then(async (res) => {
              if(res.status === "recover success"){
                this.file[i]["file_status"] = this.$t("multirecovery.document_recovered"); //document_recovered
                this.file[i]["file_value"] = 100;
                this.success_rocovery.push(this.file[i])
                this.count_restoring++;
                console.log("yess");
              }else{
                console.log("nooo");
              }
            })
            .catch((err)=>{
              check_cancel = true
              this.showrestore[i]["file_status"] = this.$t("multirecovery.recovery_cancel");
              this.showrestore[i]["file_value"] = 500;
            })

            if(check_cancel){
              this.check_dis_button = false;
              await this.timeout(600)
              this.disable_tab_success = false
              this.tab = 1
              break;
            }


          if(this.file.length - i === 1){
              this.check_dis_button = false;
              await this.timeout(600)
              this.disable_tab_success = false
              this.tab = 1
          }
         //this.scrollToBottom();
        }
      }else if(this.$router.app["_route"]["name"] === "trashoutbox"){
        this.cancelSource_doc = CancelToken.source()
        for (let i = 0; i < this.file.length; i++) {
          let check_cancel
          let objIndex = this.showrestore.findIndex((obj) => obj.file_id === this.file[i].file_id);
          //เช็คเพื่อนวนส่งข้อมูล
            this.showrestore[objIndex]["file_status"] = this.$t("multirecovery.document_recovering");
            let payload = {
                business_id : this.dataAccountActive.business_info.business_id,
                data_type : "outbox",
                inbox_id: [this.file[i]["inbox_id"]]
            };
            await this.fn_restore_v2(payload, this.file[i].file_id)
            .then(async (res) => {
              console.log("res++++",res);
              if(res.status === "recover success"){
                this.file[i]["file_status"] = this.$t("multirecovery.document_recovered");
                this.file[i]["file_value"] = 100;
                this.success_rocovery.push(this.file[i])
                this.count_restoring++;
                console.log("yess");
              }else{
                console.log("nooo");
              }
            })
            .catch((err)=>{
              check_cancel = true
              this.showrestore[i]["file_status"] = this.$t("multirecovery.recovery_cancel");
              this.showrestore[i]["file_value"] = 500;
            })

            if(check_cancel){
              this.check_dis_button = false;
              await this.timeout(600)
              this.disable_tab_success = false
              this.tab = 1
              break;
            }

              if(this.file.length - i === 1){
              this.check_dis_button = false;
              await this.timeout(600)
              this.disable_tab_success = false
              this.tab = 1
        }
        // this.scrollToBottom();
        }
      }else{  
        for(let i = 0; i < this.showrestore.length; i++){
          if(this.showrestore[i]["file_permission"] === '05' || this.showrestore[i]["file_permission"] === '06'){
            this.showrestore[i]["file_status"] = this.$t("multirecovery.recovering");
            let payload = {
              account_id: this.file.find((item) => item.file_id === this.showrestore[i].file_id).account_sender,
              user_id: this.dataUsername,
              data_id: this.showrestore[i]["file_id"],
              data_type: this.showrestore[i]["file_type"] === "folder" ? "folder" : "file",
            };
            await this.fn_restore_v2(payload, this.showrestore[i].file_id)
            .then(async (res) => {
              if(res.status === "recover success"){
                this.showrestore[i]["file_status"] = this.$t("multirecovery.recovsuccess");
                this.showrestore[i]["file_value"] = 100;
                this.showrestore[i]["file_status_message"] = ""
                this.showrestore[i]["file_status_pregress"] = 'OK'
                this.count_restoring++;
                this.success_rocovery.push(this.showrestore[i])
              } 
              else if(res.status === "wait to move"){
                let path_temp = this.showrestore[i]["full_path"].split('/')
                this.count_waittomove++;
                this.showrestore[i]["file_status"] = this.$t("multirecovery.waittomove");
                this.showrestore[i]["file_value"] = 100;
                this.showrestore[i]["file_status_message"] = res.msg
                this.showrestore[i]["file_status_pregress"] = 'W'
                this.showrestore[i]["full_path"] = `/${this.showrestore[i]["full_path"].split('/')[0]} / ... / ${path_temp[path_temp.length - 2]} / ${path_temp[path_temp.length - 1]}`
                this.nonesuccess_recovery.push(this.showrestore[i])
              }
              else{
                this.count_restoreerror++
                this.showrestore[i]["file_status"] = this.$t("multirecovery.recovfail");
                this.showrestore[i]["file_value"] = 500;
                this.showrestore[i]["file_status_pregress"] = 'ERR'
                // this.nonesuccess_recovery.push(this.showrestore[i])
              }
            });
          }
          else {
            //this.showrestore[i]["file_status"] = this.$t("multirecovery.notallowpermission");
            this.showrestore[i]["file_status_pregress"] = 'ERR'
            this.showrestore[i]["file_value"] = 100;
          }
          //this.scrollToBottom();
          if(this.showrestore.length - i === 1){
              this.check_dis_button = false;
              this.check_move_dis_button = true
              await this.timeout(600)
              if(this.count_waittomove > 0 && this.count_restoring > 0 ){
                this.tab = 0
                this.show_all_select = true
                this.disable_tab_nonesuccess = false
                this.disable_tab_success = false
                this.checkgroupchildparent()
                console.log("Something wrong tab 1")
              }else if (this.count_waittomove > 0 && this.count_restoring <= 0){
                this.tab = 0
                this.show_all_select = true
                this.disable_tab_nonesuccess = false
                this.checkgroupchildparent()
                console.log("Something wrong tab 2")
              } 
              else if (this.count_restoring > 0 || this.count_restoreerror > 0 && this.count_waittomove <= 0  ) {
                this.tab = 1
                this.show_all_select = false
                this.disable_tab_success = false
                console.log("Something wrong tab 3")
              } else { 
                console.log("Something wrong tab 4")
              }
              


              //sort wait move file/folder and complete or error
              let waitMoveItem = []
              let completeProgress = []
              let deniedpermission = []
              //deniedpermission = this.showrestore.filter((item) => item.permission_account_v2 !== '05' || item.permission_account_v2 !== '06' )
              waitMoveItem = this.showrestore.filter((item) => item.file_status_pregress === 'W')
              completeProgress = this.showrestore.filter((item) => item.file_status_pregress === 'OK' || item.file_status_pregress === 'ERR' )
              this.showrestore = [...waitMoveItem, ...completeProgress]
          }
        }
      }
    },
    
    //ฟังก์ชั่นวนกู้คืนไฟล์
    async fn_multirestore() {
      if(this.$router.app["_route"]["name"] === "trashinbox"){
        for (let i = 0; i < this.file.length; i++) {
          let objIndex = this.showrestore.findIndex((obj) => obj.file_id === this.file[i].file_id);
          //เช็คเพื่อนวนส่งข้อมูล
            this.showrestore[objIndex]["file_status"] = "กำลังกู้คืน";
            // let payload = {
            //   file_id:[this.file[i]["file_id"]],
            //   account_id:this.dataAccountId,
            //   status:"trash_inbox"
            // };
            let payload = {
                business_id : this.dataAccountActive.business_info.business_id,
                data_type : "inbox",
                inbox_id: [this.file[i]["inbox_id"]]
            };
            // console.log("payload",payload);
            await this.fn_restore(payload, this.file[i].file_id).then(async (res) => {
              if(res.status === "recover success"){
                this.count_restoring++;
                console.log("yess");
              }else{
                console.log("nooo");
              }
            });
          if(this.file.length - i === 1){
          this.check_dis_button = false;
        }
         this.scrollToBottom();
        }
      }else if(this.$router.app["_route"]["name"] === "trashoutbox"){
        for (let i = 0; i < this.file.length; i++) {
          let objIndex = this.showrestore.findIndex((obj) => obj.file_id === this.file[i].file_id);
          //เช็คเพื่อนวนส่งข้อมูล
            this.showrestore[objIndex]["file_status"] = "กำลังกู้คืน";
            // let payload = {
            //   file_id:[this.file[i]["file_id"]],
            //   account_id:this.dataAccountId,
            //   status:"trash_outbox"
            // };
            let payload = {
                business_id : this.dataAccountActive.business_info.business_id,
                data_type : "outbox",
                inbox_id: [this.file[i]["inbox_id"]]
            };
            // console.log("payload",payload);
            // console.log("file----",this.file[i].file_id);
            await this.fn_restore(payload, this.file[i].file_id)
            .then(async (res) => {
              console.log("res++++",res);
              if(res.status === "recover success"){
                this.count_restoring++;
                console.log("yess");
              }else{
                console.log("nooo");
              }
            });
              if(this.file.length - i === 1){
              this.check_dis_button = false;
        }
         this.scrollToBottom();
        }
      }else{
        for (let i = 0; i < this.file.length; i++) {
          let objIndex = this.showrestore.findIndex((obj) => obj.file_id === this.file[i].file_id);
          //เช็คเพื่อนวนส่งข้อมูล
          if(this.file[objIndex].permission_account_v2 === '05' || this.file[objIndex].permission_account_v2 === '06'){
            this.showrestore[objIndex]["file_status"] = "กำลังกู้คืน";
            let payload = {
              account_id: this.file[i]["account_sender"],
              user_id: this.dataUsername,
              data_id: this.file[i]["file_id"],
              data_type: this.file[i]["file_type"] === "folder" ? "folder" : "file",
            };
            // console.log("payload",payload);
            // console.log("file----",this.file[i].file_id);
            await this.fn_restore(payload, this.file[i].file_id)
            .then(async (res) => {
              // console.log("res++++",res);
              if(res.status === "recover success"){
                this.count_restoring++;
                console.log("yess");
              }else{
                console.log("nooo");
              }
            });
          }else{
             this.showrestore[objIndex]["file_status"] = "ไม่มีสิทธิ์กู้คืนไฟล์";
             this.showrestore[objIndex]["file_value"] = 100;
          }
              if(this.file.length - i === 1){
              this.check_dis_button = false;
        }
         this.scrollToBottom();
        }
      }
     
    },
    scrollToBottom() {
      var containers = document.querySelector(".scroll");
      var scrollHeight = containers.scrollHeight;
      containers.scrollTop = containers.scrollTop + 70
    },
    scrollToBottom_v2(item) {
      if(this.resolutionScreen >= 500){ // Add new
        item.scrollIntoView({behavior: "smooth"})
        
      }
    },
    scrollToBottom_v3 (itemHeight) {
      if(this.resolutionScreen >= 500){
        this.$refs[this.tab === 0 ? 'scrollCardWait' : 'scrollCardAll'].scrollTo({top: itemHeight, behavior: 'smooth'})
      }
    },

    //กู้คืนไฟล์/โฟลเดอร์
    async fn_restore(payload, file_id) {
      console.log("fn_restore file_id",file_id);
      if(this.$router.app["_route"]["name"] === "trashinbox"){
        console.log("in");
        let objIndex = this.showrestore.findIndex((obj) => obj.file_id === file_id);
        let auth = await gbfGenerate.generateToken();
        let result = await this.axios({
          method: "POST",
          // url: process.env.VUE_APP_SERVICE_DOWNLOAD_API + "/api/recover_inbox_outbox",
          url: process.env.VUE_APP_SERVICE_DOWNLOAD_API + "/api/v1/recovery_file_inbox_outbox",
          data: payload,
         headers: { Authorization: auth.code },

          onUploadProgress: (e) => {
            if ((e.loaded / e.total) * 100 >= 95) {
              this.showrestore[objIndex]["file_value"] = 95;
            } else {
              this.showrestore[objIndex]["file_value"] = parseInt((e.loaded / e.total) * 100);
            }
          },
        });
        return new Promise((resolve, reject) => {
          try {
            if (result.data.status === "OK") {
              this.showrestore[objIndex]["file_status"] = "กู้คืนไฟล์สำเร็จ";
              this.showrestore[objIndex]["file_value"] = 100;
              setTimeout(() => {
                    resolve({ status: "recover success", msg: "OK", code: "" });
                      }, 500);
              
            } else {
              this.showrestore[objIndex]["file_status"] = "ไม่สามารถกู้คืนไฟล์นี้ได้";
              this.showrestore[objIndex]["file_value"] = 500;
              setTimeout(() => {
                    resolve({ status: "recover denied", msg: ": ", code: "" });
                      }, 500);
              
            }
          } catch (err) {
            setTimeout(() => {
                    resolve({ status: "recover denied", msg: ": " + err, code: "" });
                      }, 500);
            this.showrestore[objIndex]["file_status"] = "ไม่สามารถกู้คืนไฟล์นี้ได้";
            this.showrestore[objIndex]["file_value"] = 500;
          }
        });
      }else if(this.$router.app["_route"]["name"] === "trashoutbox"){
        console.log("out");
        let objIndex = this.showrestore.findIndex((obj) => obj.file_id === file_id);
        console.log("objIndex",objIndex);
        let auth = await gbfGenerate.generateToken();
        let result = await this.axios({
          method: "POST",
          // url: process.env.VUE_APP_SERVICE_DOWNLOAD_API + "/api/recover_inbox_outbox",
          url: process.env.VUE_APP_SERVICE_DOWNLOAD_API + "/api/v1/recovery_file_inbox_outbox",
          data: payload,
           headers: { Authorization: auth.code },


          onUploadProgress: (e) => {
            console.log(e);
            if ((e.loaded / e.total) * 100 >= 95) {
              this.showrestore[objIndex]["file_value"] = 95;
            } else {
              this.showrestore[objIndex]["file_value"] = parseInt((e.loaded / e.total) * 100);
            }
          },
        });
        return new Promise((resolve, reject) => {
          try {
            if (result.data.status === "OK") {
              this.showrestore[objIndex]["file_status"] = "กู้คืนไฟล์สำเร็จ";
              this.showrestore[objIndex]["file_value"] = 100;
              setTimeout(() => {
                    resolve({ status: "recover success", msg: "OK", code: "" });
                      }, 500);
              
            } else {
              this.showrestore[objIndex]["file_status"] = "ไม่สามารถกู้คืนไฟล์นี้ได้";
              this.showrestore[objIndex]["file_value"] = 500;
              setTimeout(() => {
                    resolve({ status: "recover denied", msg: ": ", code: "" });
                      }, 500);
              
            }
          } 
          catch (err) {
            this.showrestore[objIndex]["file_status"] = "ไม่สามารถกู้คืนไฟล์นี้ได้";
            this.showrestore[objIndex]["file_value"] = 500;
            setTimeout(() => {
                    resolve({ status: "recover denied", msg: ": " + err, code: "" });
                      }, 500);
          }
        });
      }else{
         console.log("in");
        let objIndex = this.showrestore.findIndex((obj) => obj.file_id === file_id);
        let auth = await gbfGenerate.generateToken();
        let result = await this.axios({
          method: "POST",
          url: process.env.VUE_APP_SERVICE_UPDATE_MULTIPLES + "/api/recovery_file_folder_from_trash",
          data: payload,
          headers: { Authorization: auth.code },

          onUploadProgress: (e) => {
            if ((e.loaded / e.total) * 100 >= 95) {
              this.showrestore[objIndex]["file_value"] = 95;
            } else {
              this.showrestore[objIndex]["file_value"] = parseInt((e.loaded / e.total) * 100);
            }
          },
        });
        return new Promise((resolve, reject) => {
          try {
            if (result.data.status === "OK") {
              this.showrestore[objIndex]["file_status"] = "กู้คืนไฟล์สำเร็จ";
              this.showrestore[objIndex]["file_value"] = 100;
              setTimeout(() => {
                    resolve({ status: "recover success", msg: "OK", code: "" });
                      }, 500);
              
            } else {
              this.showrestore[objIndex]["file_status"] = "ไม่สามารถกู้คืนไฟล์นี้ได้";
              this.showrestore[objIndex]["file_value"] = 500;
              setTimeout(() => {
                    resolve({ status: "recover denied", msg: ": ", code: "" });
                      }, 500);
              
            }
          } catch (err) {
            setTimeout(() => {
                    resolve({ status: "recover denied", msg: ": " + err, code: "" });
                      }, 500);
            this.showrestore[objIndex]["file_status"] = "ไม่สามารถกู้คืนไฟล์นี้ได้";
            this.showrestore[objIndex]["file_value"] = 500;
          }
        });
      }
    },

    // ## กู้คืนไฟล์ โฟลเดอร์ (dialog inbox outbox)
    async fn_restore_v2(payload, file_id) {
      if(this.$router.app["_route"]["name"] === "trashinbox"){
        console.log("in");
        let objIndex = this.showrestore.findIndex((obj) => obj.file_id === file_id);
        let auth = await gbfGenerate.generateToken();
        let result = await this.axios({
          method: "POST",
          // url: process.env.VUE_APP_SERVICE_DOWNLOAD_API + "/api/recover_inbox_outbox",
          url: process.env.VUE_APP_SERVICE_DOWNLOAD_API + "/api/v1/recovery_file_inbox_outbox",
          data: payload,
         headers: { Authorization: auth.code },
         cancelToken: this.cancelSource_doc.token,

          onUploadProgress: (e) => {
            if ((e.loaded / e.total) * 100 >= 95) {
              this.showrestore[objIndex]["file_value"] = 95;
            } else {
              this.showrestore[objIndex]["file_value"] = parseInt((e.loaded / e.total) * 100);
            }
          },
        });
        return new Promise((resolve, reject) => {
          try {
            if (result.data.status === "OK") {
              this.showrestore[objIndex]["file_status"] = this.$t("multirecovery.document_recovered"); // document_recovered
              this.showrestore[objIndex]["file_value"] = 100;
              setTimeout(() => {
                    resolve({ status: "recover success", msg: "OK", code: "" });
                      }, 500);
              
            } else {
              this.showrestore[objIndex]["file_status"] = this.$t("multirecovery.recovfail"); // document_fail_recovered
              this.showrestore[objIndex]["file_value"] = 500;
              setTimeout(() => {
                    resolve({ status: "recover denied", msg: ": ", code: "" });
                      }, 500);
              
            }
          } catch (err) {
            setTimeout(() => {
                    resolve({ status: "recover denied", msg: ": " + err, code: "" });
                      }, 500);
            this.showrestore[objIndex]["file_status"] = this.$t("multirecovery.recovfail");
            this.showrestore[objIndex]["file_value"] = 500;
          }
        });
      }else if(this.$router.app["_route"]["name"] === "trashoutbox"){
        console.log("out");
        let objIndex = this.showrestore.findIndex((obj) => obj.file_id === file_id);
        console.log("objIndex",objIndex);
        let auth = await gbfGenerate.generateToken();
        let result = await this.axios({
          method: "POST",
          // url: process.env.VUE_APP_SERVICE_DOWNLOAD_API + "/api/recover_inbox_outbox",
          url: process.env.VUE_APP_SERVICE_DOWNLOAD_API + "/api/v1/recovery_file_inbox_outbox",
          data: payload,
          headers: { Authorization: auth.code },
          cancelToken: this.cancelSource_doc.token,


          onUploadProgress: (e) => {
            console.log(e);
            if ((e.loaded / e.total) * 100 >= 95) {
              this.showrestore[objIndex]["file_value"] = 95;
            } else {
              this.showrestore[objIndex]["file_value"] = parseInt((e.loaded / e.total) * 100);
            }
          },
        });
        return new Promise((resolve, reject) => {
          try {
            if (result.data.status === "OK") {
              this.showrestore[objIndex]["file_status"] = this.$t("multirecovery.document_recovered");
              this.showrestore[objIndex]["file_value"] = 100;
              setTimeout(() => {
                    resolve({ status: "recover success", msg: "OK", code: "" });
                      }, 500);
              
            } else {
              this.showrestore[objIndex]["file_status"] = this.$t("multirecovery.recovfail");
              this.showrestore[objIndex]["file_value"] = 500;
              setTimeout(() => {
                    resolve({ status: "recover denied", msg: ": ", code: "" });
                      }, 500);
              
            }
          } 
          catch (err) {
            this.showrestore[objIndex]["file_status"] = this.$t("multirecovery.recovfail");
            this.showrestore[objIndex]["file_value"] = 500;
            setTimeout(() => {
                    resolve({ status: "recover denied", msg: ": " + err, code: "" });
                      }, 500);
          }
        });
      }else{
        let objIndex = this.showrestore.findIndex((obj) => obj.file_id === file_id);
        let auth = await gbfGenerate.generateToken();
        let result = await this.axios({
          method: "POST",
          url: process.env.VUE_APP_SERVICE_UPDATE_MULTIPLES + "/api/recovery_data_from_trash",
          data: payload,
          headers: { Authorization: auth.code },

          onUploadProgress: (e) => {
            if ((e.loaded / e.total) * 100 >= 95) {
              this.showrestore[objIndex]["file_value"] = 95;
            } else {
              this.showrestore[objIndex]["file_value"] = parseInt((e.loaded / e.total) * 100);
            }
          },
        });
        return new Promise((resolve, reject) => {
          console.log("Respond data ", result.data)
          try {
            if (result.data.status === "OK") {
              setTimeout(() => {
                    resolve({ status: "recover success", msg: result.data.message, code: "" });
                      }, 500);
              
            } else {
              if(result.data.errorCode === "ER205"){
                  setTimeout(() => {
                        resolve({ status: "wait to move", msg: this.$t("multirecovery.filenoparent"), code: "" });
                  }, 500);
                
              }
              else if (result.data.errorCode === "ER209") {
                setTimeout(() => {
                        resolve({ status: "wait to move", msg: this.$t("multirecovery.filenopermission"), code: "" });
                  }, 500);
              }
              else{
                  setTimeout(() => {
                        resolve({ status: "recover denied", msg: ": ", code: "" });
                          }, 500);
                          
              }
            }
          } catch (err) {
            setTimeout(() => {
                    resolve({ status: "recover denied", msg: ": " + err, code: "" });
                      }, 500);
          }
        });
      }
    },

    // ## ย้ายไฟลโฟลเดอร (เฉพาะ Mobile)
    async movefilefolder_trash ( ) {
      let newAllSort = []
      let newSortFolder = []
      let newSortFile = []
      newSortFolder = this.selectionWithParents.filter((item) => item.file_type === "folder").sort(function(a, b) {
              return a.trash_dtm.localeCompare(b.trash_dtm);
      }).reverse()
      newSortFile = this.selectionWithParents.filter((item) => item.file_type !== "folder").sort(function(a, b) {
            return a.trash_dtm.localeCompare(b.trash_dtm);
      }).reverse()
      newAllSort = [...newSortFolder, ...newSortFile]
      this.$emit('opendialogmovefileandfolder', newAllSort)
    },

     // ## ย้ายไฟลโฟลเดอร (เฉพาะ Window)
    async movefilefolder_trash_v2 ( ) {
      this.$emit('opendialogmovefileandfolder', this.multiitemwaitmove_v2)
    },

   
    async findPath (arraytempgroup, name_folder, children, indexpointer, procressedId ) { // ## ไม่ใช้งาน
      for(var k = indexpointer; k < arraytempgroup.length; k++){
        if(procressedId.filter((val) => val === arraytempgroup[k].id).length <= 0){
          if(arraytempgroup[k]["data_type"] === 'folder'){
            let full_path_check = arraytempgroup[k]["full_path"].split('/')
            let indexof_namefolder = full_path_check.findIndex((item) => item === name_folder)
            full_path_check = full_path_check.slice(indexof_namefolder,)
              if(full_path_check.length === 2){
                procressedId.push(arraytempgroup[k].id)
                let name_folder = arraytempgroup[k].name
                let tempChild = this.nonesuccess_recovery.filter((item) => item.file_id === arraytempgroup[k].id)[0]
                tempChild["id"] = tempChild["file_id"]
                tempChild["name"] = tempChild["file_name"]
                tempChild["children"] = await this.findPath(arraytempgroup, name_folder, [], indexpointer + 1, procressedId )
                children.push(tempChild)
              } 
        } else {
            let full_path_check = arraytempgroup[k]["full_path"].split('/')
            let indexof_namefolder = full_path_check.findIndex((item) => item === name_folder)
            full_path_check = full_path_check.slice(indexof_namefolder,)
            if(full_path_check.length === 2){
              let tempItem = this.nonesuccess_recovery.filter((item) => item.file_id === arraytempgroup[k].id)[0]
              tempItem["id"] = tempItem["file_id"]
              tempItem["name"] = tempItem["file_name"]
              procressedId.push(arraytempgroup[k].id)
              children.push(tempItem)
            }
        } 
        } else {
          continue
        }     
    }
    return children
    },
    
    async checkgroupchildparent ( ) { // ## ไม่ใช้งาน
      this.nonsuccess_loading = true
      await this.timeout(1000)
      let folder_type_payload = this.nonesuccess_recovery
      .filter((item) => item.file_type === 'folder')
      .map((item) => item.file_id)
      let file_type_payload = this.nonesuccess_recovery
      .filter((item) => item.file_type !== 'folder')
      .map((item) => item.file_id)
      let payload = {
        user_id: this.dataUsername,
        account_id: this.dataAccountId,
        data: {folder: folder_type_payload, file: file_type_payload}
      }
      let auth = await gbfGenerate.generateToken();
      await this.axios
      .post(
          process.env.VUE_APP_SERVICE_DOWNLOAD_API + "/api/group_data_for_move_trash",
          payload,
          { headers: { Authorization: auth.code } }
      )
      .then(async (res) => {
        let arraytempgroup = res.data.data
        console.log('Array temp group ', arraytempgroup)
        let procressedId = []
        this.nonsuccess_group_recovery = []
        for(var i = 0; i < arraytempgroup.length; i++){
          if(procressedId.length === 0 || procressedId.filter((val) => val === arraytempgroup[i].id).length <= 0){
            if(arraytempgroup[i].data_type !== 'folder'){
            let tempItem = this.nonesuccess_recovery.filter((item) => item.file_id === arraytempgroup[i].id)[0]
            tempItem["id"] = tempItem["file_id"]
            tempItem["name"] = tempItem["file_name"]
            tempItem['file_type'] = tempItem["file_type"]
            tempItem["role"] = "P"
            tempItem["selected"] = false
            // let newObject = {
            //   name: arraytempgroup[i].name,
            //   id: arraytempgroup[i].id,
            // }
            this.nonsuccess_group_recovery.push(tempItem)
            procressedId.push(arraytempgroup[i].id)
          }
          else{
            let pathcheck = arraytempgroup[i].full_path.split('/').slice(-1)[0]
            let indexpath = arraytempgroup[i].full_path.split('/').findIndex((val) => val === pathcheck)
            let children = []
            let tempItem = this.nonesuccess_recovery.filter((item) => item.file_id === arraytempgroup[i].id)[0]
            tempItem["id"] = tempItem["file_id"]
            tempItem["name"] = tempItem["file_name"]
            tempItem['file_type'] = tempItem["file_type"]
            tempItem["role"] = "P"
            tempItem["selected"] = false
            tempItem["children"] = await this.findPath(arraytempgroup, children, pathcheck, indexpath, procressedId, i+1)
            // this.nonsuccess_group_recovery.push({
            //   name: arraytempgroup[i].name,
            //   id: arraytempgroup[i].id,
            //   children: await this.findPath(arraytempgroup, children, pathcheck, indexpath, procressedId, i+1)
            // })
            this.nonsuccess_group_recovery.push(tempItem)
          }
          }
        }
        this.nonsuccess_loading = false 
        this.nonsuccess_group_recovery = [...this.nonsuccess_group_recovery.filter((item) => item.file_type === 'folder'), ...this.nonsuccess_group_recovery.filter((item) => item.file_type !== 'folder')]
        console.log("Finally non success group ", this.nonsuccess_group_recovery)
      })
      .catch((err) => {
        console.log("Error Some ", err)
      })

    },

    async checkgroup () { // ## ใช้งาน
      this.nonsuccess_loading = false 
      this.nonsuccess_group_recovery = [
        ...this.nonsuccess_group_recovery.filter((item) => item.file_type === 'folder'), 
        ...this.nonsuccess_group_recovery.filter((item) => item.file_type !== 'folder')]
    },

    // CLEAR METHOD ///

    async clearalldata () {
      this.$emit('closedialogreload')
      await this.timeout(1000)
      this.check_restore = false
      this.check_show_progress = false;
      this.check_move_dis_button = false 
      this.cancelCheck = false
      this.cancelSource_doc =""
      this.showrestore = []
      this.multiitemwaittimove = []
      this.tempOpenIds = []
      this.group_recovery = []
      this.nonsuccess_group_recovery = []
      this.multiitemwaitmove_v2 = []
      this.count_waittomove = 0
      this.show_all_select = false
      this.check_dis_button = true
      this.tab = null
      this.disable_tab_success = true
      this.disable_treeview_loading = false
      this.nonsuccess_loading = true
      this.disable_tab_nonesuccess = true
      this.success_rocovery = []
      this.nonesuccess_recovery = []
      this.heightGroupItem = 0
      this.duplicate_file_recovery = []
      this.count_duplicate = 0
    },

    // TRANSFORM TREEVIEW FORMAT ///

    depressed_file_id (arr, temp) {
      for(var i = 0; i < arr.length; i++){
        temp.push(arr[i])
        if(arr[i].file_type === 'folder'){
          this.depressed_file_id(arr[i].children, temp)
        }
      }
      return temp
    },

    depressed_subfilefolder_id (arr, tempfolder, tempfile) {
      for(var i = 0; i < arr.length; i++){
        if(arr[i].file_type === 'folder'){
          tempfolder.push(arr[i].file_id)
          this.depressed_subfilefolder_id(arr[i].children, tempfolder, tempfile)
        } else {
          tempfile.push(arr[i].file_id)
        }
      }
      return {tempfolder, tempfile}
    },

    async changeTreeview (treeStatus) { // ## ไม่ได้ใช้งาน
        this.treeviewfile = treeStatus
        let arraytempgroup = this.showrestore
        console.log("thisss show ", this.showrestore)
        let procressedId = []
        this.group_recovery = []
        for(var i = 0; i < arraytempgroup.length; i++){
          if(procressedId.length === 0 || procressedId.filter((val) => val === arraytempgroup[i].file_id).length <= 0){
            if(arraytempgroup[i].file_type !== 'folder'){
            let tempItem = this.showrestore.filter((item) => item.file_id === arraytempgroup[i].file_id)[0]
            tempItem["id"] = tempItem["file_id"]
            tempItem["name"] = tempItem["file_name"]
            tempItem["role"] = "P"
            tempItem["selected"] = false
            this.group_recovery.push(tempItem)
            procressedId.push(arraytempgroup[i].file_id)
          } else{
            let pathcheck = arraytempgroup[i].full_path.split('/').slice(-1)[0] // ** Case
            let indexpath = arraytempgroup[i].full_path.split('/').findIndex((val) => val === pathcheck) 
            let children = []
            let tempItem = this.showrestore.filter((item) => item.file_id === arraytempgroup[i].file_id)[0]
            tempItem["id"] = tempItem["file_id"]
            tempItem["name"] = tempItem["file_name"]
            tempItem["role"] = "P"
            tempItem["selected"] = false
            tempItem["children"] = await this.findPathTreeview(arraytempgroup, children, pathcheck, indexpath, procressedId, i+1)
            this.group_recovery.push(tempItem)
          }
          }
        }
        console.log("Group recovery ", this.group_recovery)
    },

    async findPathTreeview (arraytempgroup, children, path, indexpath, procressedId, indexpointer) {  // ## ไม่ได้ใช้งาน
      for(var k = indexpointer; k < arraytempgroup.length; k++){
        if(procressedId.filter((val) => val === arraytempgroup[k].file_id).length <= 0){
          if(arraytempgroup[k].file_type !== 'folder'){
            if(arraytempgroup[k].full_path.split('/')[indexpath] === path){
                procressedId.push(arraytempgroup[k].file_id)
                let tempItem = this.showrestore.filter((item) => item.file_id === arraytempgroup[k].file_id)[0]
                tempItem["id"] = tempItem["file_id"]
                tempItem["name"] = tempItem["file_name"]
                children.push(tempItem)
                }
          } else{
            // let arrayfullpath = arraytempgroup[k].full_path.split('/')
            // let findIndexOpath = arrayfullpath.findIndex((item) => item === path)
            // arrayfullpath = arrayfullpath.slice(findIndexOpath)
            // console.log('Slice Array ', arrayfullpath)
            // console.log("Index path ", indexpath)
            if(arraytempgroup[k].full_path.split('/')[indexpath] === path){ //arraytempgroup[k].full_path.split('/')
                let pathcheck = arraytempgroup[k].full_path.split('/').slice(-1)[0]
                let indexpath = arraytempgroup[k].full_path.split('/').findIndex((val) => val === pathcheck)
                procressedId.push(arraytempgroup[k].file_id)
                let tempItem = this.showrestore.filter((item) => item.file_id === arraytempgroup[k].file_id)[0]
                tempItem["id"] = tempItem["file_id"]
                tempItem["name"] = tempItem["file_name"]
                tempItem["children"] = await this.findPathTreeview (arraytempgroup, [], pathcheck, indexpath, procressedId, indexpointer + 1)
                children.push(tempItem)
            }
          } 
        } else {
          console.log("Continue prcovreess ")
          continue
        }     
    }
    return children
    },

    async changeTreeview_v2 () { // ## ไม่ได้ใช้งาน Loop Parent Here
      let temparry = this.showrestore
      let procressId = []
      this.group_recovery = []
      for(var i = 0; i < temparry.length; i++){
        if(procressId.filter((val) => val === temparry[i].file_id).length <= 0){
          if(temparry[i].file_type === 'folder'){
            let name_folder = temparry[i].file_name
            let file_id = temparry[i].file_id
            let children = []
            let tempItem = this.showrestore.filter((item) => item.file_id === temparry[i].file_id)[0]
            tempItem["id"] = tempItem["file_id"]
            tempItem["name"] = tempItem["file_name"]
            tempItem["role"] = "P"
            tempItem["procress"] = false
            tempItem["selected"] = false
            tempItem['children'] = await this.grouptreeview_v2(temparry, name_folder, children, i+1, procressId)
            procressId.push(temparry[i].file_id)
            this.group_recovery.push(tempItem)
          } else {
            let tempItem = this.showrestore.filter((item) => item.file_id === temparry[i].file_id)[0]
            tempItem["id"] = tempItem["file_id"]
            tempItem["name"] = tempItem["file_name"]
            tempItem["role"] = "P"
            tempItem["procress"] = false
            tempItem["selected"] = false
            procressId.push(temparry[i].file_id)
            this.group_recovery.push(tempItem)
          }
        } 
      }
      console.log("Group recovery ", this.group_recovery)
    },

    async grouptreeview_v2 (alldata, name_folder, child, pointer, proId) { // ## ไม่ได้ใช้งาน Loop Children Here
      for(var k = pointer; k < alldata.length; k++){
        if(proId.filter((val) => val === alldata[k].file_id).length <= 0){
        if(alldata[k]["file_type"] === 'folder'){
          let full_path_check = alldata[k]["full_path"].split('/')
          let indexof_namefolder = full_path_check.findIndex((item) => item === name_folder)
          full_path_check = full_path_check.slice(indexof_namefolder,)
          if(full_path_check.length >= 2){
            proId.push(alldata[k].file_id)
            let name_folder = alldata[k].file_name
            let tempChild = this.showrestore.filter((item) => item.file_id === alldata[k].file_id)[0]
            tempChild["id"] = tempChild["file_id"]
            tempChild["name"] = tempChild["file_name"]
            tempChild["children"] = await this.grouptreeview_v2(alldata, name_folder, [], pointer + 1, proId )
            child.push(tempChild)
          } 
        } else {
          let full_path_check = alldata[k]["full_path"].split('/')
          let indexof_namefolder = full_path_check.findIndex((item) => item === name_folder)
          full_path_check = full_path_check.slice(indexof_namefolder,)
          if(full_path_check.length >= 2){
            let tempItem = this.showrestore.filter((item) => item.file_id === alldata[k].file_id)[0]
            tempItem["id"] = tempItem["file_id"]
            tempItem["name"] = tempItem["file_name"]
            proId.push(alldata[k].file_id)
            child.push(tempItem)
          }
        }
          
        } else{
          continue
        }

      }
      
      return child
    },

    async changeTreeview_v3 () { // ## ใช้งาน Loop Parent ทั้งไฟลและโฟลเดอ
      let temparry = this.showrestore
      let procressId = []
      this.group_recovery = []
      for(var i = 0; i < temparry.length; i++){
        if(procressId.filter((val) => val === temparry[i].file_id).length <= 0){ // ## เช็คการทำงานของ file/folder นั้นๆ
          if(temparry[i].file_type === 'folder'){
            let file_id = temparry[i].file_id // ## เก็บ full_path
            let children = []
            let tempItem = this.showrestore.filter((item) => item.file_id === temparry[i].file_id)[0]
            tempItem["id"] = tempItem["file_id"]
            tempItem["name"] = tempItem["file_name"]
            tempItem["role"] = "P"
            tempItem["procress"] = false
            tempItem["selected"] = false
            tempItem["message"] = ""
            tempItem['children'] = await this.grouptreeview_v3(temparry, file_id, children, i+1, procressId) 
            procressId.push(temparry[i].file_id)
            this.group_recovery.push(tempItem)
          } else {
            let tempItem = this.showrestore.filter((item) => item.file_id === temparry[i].file_id)[0]
            tempItem["id"] = tempItem["file_id"]
            tempItem["name"] = tempItem["file_name"]
            tempItem["role"] = "P"
            tempItem["procress"] = false
            tempItem["selected"] = false
            procressId.push(temparry[i].file_id)
            this.group_recovery.push(tempItem)
          }
        } 
      }

      if(this.group_recovery.filter((permis) => permis.file_permission  === '05' || permis.file_permission === '06').length > 0){
        this.checkPermissionAll = false
        }
        else {
          this.checkPermissionAll = true
        }
      console.log("Group recovery ", this.group_recovery)
    },

    async grouptreeview_v3 (alldata, file_id, child, pointer, proId) { // ## ใช้งาน Loop Children ทั้งไฟลและโฟลเดอ
      for(var k = pointer; k < alldata.length; k++){
        if(proId.filter((val) => val === alldata[k].file_id).length <= 0){
        if(alldata[k]["file_type"] === 'folder'){
          // ## ทำ full_path เป็น Array ['a', 'b', 'c']
          let full_path_id_check = alldata[k]["full_path_id"].split('/') 
          //  ## หา index ของ array full_path ที่ตรงกับโฟลเดอนั้นๆ ex: a = ['a', 'b', 'c'] = 0
          let indexof_full_path_folder = full_path_id_check.findIndex((item) => item === file_id ) 
          // ## slice ตั้งแต่ index นั้นๆถึงตัวสุดท้าย ex: a -> ['a', 'b', 'c']  =  ['a', 'b', 'c'] , a -> [ 'e', 'a', 'b', 'c']  =  ['a', 'b', 'c']
          full_path_id_check = full_path_id_check.slice(indexof_full_path_folder,) 

          // ## เช็คว่า array full_path นั้น = 2 ? จะใส่ลงfolderนั้น : จะไม่ใส่ลงfolderนั้น 
          if(full_path_id_check.length === 2){ 
            proId.push(alldata[k].file_id)
            let tempChild = this.showrestore.filter((item) => item.file_id === alldata[k].file_id)[0]
            tempChild["id"] = tempChild["file_id"]
            tempChild["name"] = tempChild["file_name"]
            tempChild['role'] = 'children' // เพราะยัง มันก็อยู่ parent 
            tempChild['status_change_name'] = false
            //## ถ้าเป็น folder ให้ทำการใช้ Recursive function
            tempChild["children"] = await this.grouptreeview_v3(alldata, alldata[k].file_id, [], pointer + 1, proId )
            child.push(tempChild)
          } 
        } else {
          if(alldata[k]["full_path_id"] === null || alldata[k]["full_path_id"] === "" ){
            continue
          }
          // ## ทำ full_path เป็น Array ['a', 'b', 'c']
          let full_path_id_check = alldata[k]["full_path_id"].split('/')
          //  ## หา index ของ array full_path ที่ตรงกับโฟลเดอนั้นๆ
          let indexof_full_path_folder = full_path_id_check.findIndex((item) => item === file_id )
          // ## slice ตั้งแต่ index นั้นๆถึงตัวสุดท้าย
          full_path_id_check = full_path_id_check.slice(indexof_full_path_folder,) // <- TEST

           // ## เช็คว่า array full_path นั้น = 2 ? จะใส่ลงfolderนั้น : จะไม่ใส่ลงfolderนั้น 
          if(full_path_id_check.length === 2){
            let tempItem = this.showrestore.filter((item) => item.file_id === alldata[k].file_id)[0]
            tempItem["id"] = tempItem["file_id"]
            tempItem["name"] = tempItem["file_name"]
            tempItem['role'] = 'children'
            tempItem['status_change_name'] = false
            proId.push(alldata[k].file_id)
            child.push(tempItem)
          }
        }
          
        } else{
          continue
        }

      }
      
      return child
    },

    //////////////////////////////////



    /// ***(TEST) Recovery and Move Methods V2*** ////

    // CENTER METHOD ใช้งานทั้งmethod (Recovery, Move) ///
    updatefilestatus( arr, pbid, countmiss) {
      for(var i = 0; i < arr.length; i++){
        if(pbid.includes(arr[i].file_id)){ // ## check file_id ว่ามีอยู่ใน Error ส่งกลับมาหรือป่าว
          countmiss++
          arr[i].file_status = this.$t("multirecovery.recovfail")
          if(arr[i].file_type === 'folder'){
            if(arr[i].children.length > 0){
              this.updatefilestatus_failmove(arr[i].children)
              }
          }
          
        }else{
          arr[i].file_status = this.$t("multirecovery.recovsuccess");
          arr[i].procress = true
          this.success_rocovery.push(arr[i])
          if(arr[i].file_type === 'folder'){
            if(arr[i].children.length > 0){
              this.updatefilestatus( arr[i].children, pbid, countmiss)
            }
          }
        }
      }
      return countmiss
    },

    updatefilestatus_failmove( arr) { // ##Recursive แก้ไข้ไฟลโฟลเดอทุกตัวของลูก Error 
      for(var i = 0; i < arr.length; i++){
        arr[i].file_status = this.$t('multirecovery.recovfail')
          if(arr[i].file_type === 'folder'){
            if(arr[i].children.length > 0){
               this.updatefilestatus_failmove( arr[i].children)
            }
          }
        
      }
      
      return arr
    },

    updatefilestatus_waittomove( arr) { // ##Recursive แก้ไข้ไฟลโฟลเดอทุกตัวของลูก Wait 
      for(var i = 0; i < arr.length; i++){
        arr[i].file_status = this.$t("multirecovery.waittomove");
          if(arr[i].file_type === 'folder'){
            if(arr[i].children.length > 0){
               this.updatefilestatus_waittomove( arr[i].children)
            }
          }
      }
      return arr
    },


    // RECOVEY FILE FOLDER METHOD //

    updatefilestatus_failrestore( arr) { // ##Recursive แก้ไข้ไฟลโฟลเดอทุกตัวของลูก Error
      for(var i = 0; i < arr.length; i++){
        arr[i].file_status = this.$t('multirecovery.recovfail')
          if(arr[i].file_type === 'folder'){
            if(arr[i].children.length > 0){
               this.updatefilestatus_failrestore( arr[i].children)
            }
          }
        
      }
      return arr
    },

    async summitrecoveryfile () { // ##ใไม่ได้ช้งาน
      // ## แยก group ระหว่าง Folder และ File
      let sortfiletype = [
        [...this.group_recovery.filter((item) => item.file_type === 'folder')], 
        [...this.group_recovery.filter((item) => item.file_type !== 'folder')]
      ]

      //## แบ่งการทำงาน group ของโฟลเดอร์และไฟล์
      for(var o = 0 ; o < sortfiletype.length; o++){
        let group = sortfiletype[o]
        let check_error_group = false

        // ## ไฟล์โฟลเดอร์ของแต่ละ group
        for(var i = 0; i < group.length; i++){ // [{a, {b, c}}, {d, {e, f}}]
          if(group[i].procress === true ){
            continue
          }
          if(group[i].file_permission === '05' || group[i].file_permission === '06' ){ // ## เช็คสิทธิไฟล/โฟลเดอร
            var finally_sub_fileId
            let temp_folder = []
            let temp_file = []
            group[i]["file_status"] = this.$t("multirecovery.recovering");

            // ## เก็บ sub_folder และ sub_flie ของลูกๆ
            finally_sub_fileId = await this.depressed_subfilefolder_id(group[i].file_type === 'folder' ? group[i].children : [], temp_folder, temp_file)
            
            let payload = {
              account_id: this.file.find((item) => item.file_id === group[i].file_id).account_sender,
              user_id: this.dataUsername,
              data_id: group[i]["file_id"],
              data_type: group[i]["file_type"] === "folder" ? "folder" : "file",
              sub_folder: finally_sub_fileId.tempfolder,
              sub_file: finally_sub_fileId.tempfile
            }
          
            // ## ใช้งาน API
            let resultMovefile = await this.fn_restore_filefolder(payload, group[i]) 
            if(resultMovefile.status === 'OK'){ // ## Response OK
              this.count_restoring ++
              group[i].procress = true
              this.success_rocovery.push(group[i])
              if(group[i].file_type === 'folder'){ // #อัพเดต ไฟล์หรือโฟลเดอร์ตัวลูกๆ
                let countmistake = 0
                await this.updatefilestatus(group[i].children, resultMovefile.data_miss, countmistake)
              }
            } else if (resultMovefile.status === 'WAIT TO MOVE') { // ## Response Wait to move
              try{
                this.count_waittomove++
                group[i]["file_status"] = this.$t("multirecovery.waittomove");
                group[i]["procress"] = true
                //this.nonesuccess_recovery.push(this.group_recovery[i]) // Old

                // ## เก็บ file หรือ folder แบบ treeview และอัพเดตตัวลูก (แบบใหม่)
                if(group[i].file_type !== 'folder'){
                  // this.nonesuccess_recovery.push(this.group_recovery[i]) // Old
                  this.nonsuccess_group_recovery.push(group[i])
                } else{
                  await this.updatefilestatus_waittomove(group[i].children)
                  this.nonsuccess_group_recovery.push(group[i])
                }

                // ## เก็บ Treeview group ลงใน non_success_recovery (รอย้าย)
                let arrayTest = group[i]["full_path"].split('/')
                arrayTest.pop()
                arrayTest = JSON.stringify(arrayTest) // [a, b]
                let arrayTemp = group
                .filter((item) => item.procress === false && arrayTest === JSON.stringify(item.full_path.split('/')
                .slice(0, -1)))
                .map((mapId) => mapId.id) 
                group.forEach((obj) => {if(arrayTemp.includes(obj.file_id)) { 
                  obj.procress = true 
                  if(obj.file_permission === '05' || obj.file_permission === '06' ){
                    obj.file_status =  this.$t("multirecovery.waittomove");
                    this.nonsuccess_group_recovery.push(obj)
                  }
                }})
                ////////


                // ## เก็บ file หรือ folder และอัพเดตตัวลูก (แบบเก่า)
                // if(this.group_recovery[i].file_type !== 'folder'){ 
                //   this.nonesuccess_recovery.push(this.group_recovery[i])
                // } else{
                //   let tempId = []
                //   tempId.push(this.group_recovery[i])
                //   let arraygroup = await this.depressed_file_id(this.group_recovery[i].children, tempId)
                //   this.nonesuccess_recovery = [...this.nonesuccess_recovery, ...arraygroup]
                //   await this.updatefilestatus_waittomove(this.group_recovery[i].children)
                // }
                
              } catch (err) {
                console.log("ERRORR ", err)
              }
              
            } else if (resultMovefile.status === 'FILE/FOLDER ERROR') { // ## Response File หรือ Folder Error (procressing continue)
                group[i]["file_status"] = this.$t('multirecovery.recovfail')
                group[i]["procress"] = true
                if(group[i]["file_type"] === 'folder'){
                  await this.updatefilestatus_failrestore(group[i].children)
                }
            } else { // ## NETWORK OR OTHER ERROR (procressing break)
              group[i]["file_status"] = this.$t('multirecovery.recovfail')
              check_error_group = true
              break
            }
          } else { 
            // ## Check ไฟล์โฟลเดอร์ที่ไม่มีสิทธิ์
            group[i].procress = true
          }

          // ## ส่ง element ของ node treeview ไป scroll
          let findIndexElement = this.group_recovery.findIndex((item) => item.file_id === group[i].file_id)
          this.scrollToBottom_v2(Array.from(document.getElementsByClassName('v-treeview-allfile')[0].children)[findIndexElement]) // v-treeview-node--leaf
         
          // ## check ไฟล์โฟลเดอร์ทั้งหมดว่าทำงานครบหมดทุกตัวหรือยัง
          if(this.group_recovery.filter((item) => item.procress === true).length === this.group_recovery.length){ // then this.group_recovery.length - i === 1
              this.check_dis_button = false;
              this.check_move_dis_button = true
              await this.timeout(1000)
              // ## มีไอเทมสำเร็จและรอย้าย (เปิดกู้ทั้งหมด - เปิดรอย้าย)
              if(this.count_waittomove > 0 && this.count_restoring > 0 ){
                      this.tab = 0
                      this.show_all_select = true
                      this.disable_tab_nonesuccess = false
                      this.disable_tab_success = false
                      this.checkgroup()
              }
              // ## ไม่มีไอเทมที่สำเร็จ (ปิดกู้ทั้งหมด - เปิดรอย้าย)
              else if (this.count_waittomove > 0 && this.count_restoring <= 0){
                      this.tab = 0
                      this.show_all_select = true
                      this.disable_tab_nonesuccess = false
                      this.checkgroup()
              }
              // ## ไม่มีไอเทมรอย้าย (เปิดกู้ทั้งหมด - ปิดรอย้าย)
              else if (this.count_restoring > 0 || this.count_restoreerror > 0 && this.count_waittomove <= 0  ) {
                      this.tab = 1
                      this.show_all_select = false
                      this.disable_tab_success = false
              } 
          }
        }
        
        // ## เช็ค group ที่ Error -> break 
        if(check_error_group === true ){
            this.show_all_select = true
            this.nonsuccess_loading = false 
            this.check_dis_button = false;
            break
        }
      }
    },

    async summmitrecoveryfile_v2 () { // ##ไม่ได้ใช้งาน V2
      try{
      let chuck_group_file_id = this.group_recovery.length <= 1 ? 
      [this.group_recovery] 
      : 
      await this.file_id_chunk(this.group_recovery.filter((item)=> item.file_permission === '05' || item.file_permission === '06' ), 3)
      for(var i = 0; i < chuck_group_file_id.length; i++){ // [[123], [23]]
        let check_error_round = false
        let check_cancel_round = false
        let file_id_mistake
        const promises = await chuck_group_file_id[i].map(async item => {
          item.file_status = this.$t("multirecovery.recovering");

          // ## เก็บ file_id ของโฟลเดอรที่มีลูก
          var finally_sub_fileId
              let temp_folder = []
              let temp_file = []
              finally_sub_fileId = await this.depressed_subfilefolder_id(
              item.file_type === 'folder' ? item.children : [], temp_folder, temp_file)
          
         let payload = {
              account_id: this.file.find((itemFind) => itemFind.file_id === item.file_id).account_sender,
              user_id: this.dataUsername,
              data_id: item.file_id,
              data_type: item.file_type === "folder" ? "folder" : "file",
              sub_folder: finally_sub_fileId.tempfolder,
              sub_file: finally_sub_fileId.tempfile
            }

          const responseRecovery =  await this.fn_restore_filefolder (item, payload)
          return responseRecovery
        })
        await Promise.all(promises)
        .then(async(AllRes) => {
          for(const itemFile of AllRes){
            if(itemFile.status === 'OK'){
              this.count_restoring ++
              this.success_rocovery.push(this.group_recovery.find((item) => item.file_id === itemFile.file_id ))
              if(itemFile.file_type === 'folder'){ // #อัพเดต ไฟล์หรือโฟลเดอร์ตัวลูกๆ
                let countmistake = 0
                //await this.updatefilestatus(itemFile.children, itemFile.data_miss, countmistake)

                //เพิ่ม snipped
                if(await this.updatefilestatus(itemFile.children, itemFile.data_miss, countmistake) > 0){ // ## เช็ค datamiss ที่ส่งมาบาง fileid ของลูกๆนั้น
                    let findIndexItem = this.group_recovery.findIndex((item) => item.file_id === itemFile.file_id)
                    this.group_recovery[findIndexItem]["message"] = 'บางไฟล์/โฟลเดอร์กู้คืนไม่สำเร็จ'
                  }
              }
            } 
            else if (itemFile.status === 'WAIT TO MOVE') {
              try{
                this.count_waittomove++
                let findIndexError = this.group_recovery.findIndex((item) => item.file_id === itemFile.file_id)
                this.group_recovery[findIndexError]["file_status"] = this.$t("multirecovery.waittomove");

                // ## เก็บ file หรือ folder แบบ treeview และอัพเดตตัวลูก (แบบใหม่)
                if(this.group_recovery[findIndexError] !== 'folder'){
                  // this.nonesuccess_recovery.push(this.group_recovery[i]) // Old
                  this.nonsuccess_group_recovery.push(this.group_recovery[findIndexError])
                } else{
                  await this.updatefilestatus_waittomove(this.group_recovery[findIndexError].children)
                  this.nonsuccess_group_recovery.push(this.group_recovery[findIndexError])
                }
              } catch (err) {
                console.log("ERRORR ", err)
              }

            } 
            else if (itemFile.status === 'FILE/FOLDER ERROR') {
                let findIndexError = this.group_recovery.findIndex((item) => item.file_id === itemFile.file_id)
                this.group_recovery[findIndexError]["file_status"] = this.$t("multirecovery.recovfail");
                if(this.group_recovery[i].file_type === 'folder'){
                  await this.updatefilestatus_failmove(itemFile.children)
                }
              } 
            else {
              check_error_round = true
              file_id_mistake = itemFile
            }
          }
        })


        let group_element_node_ = await this.slice_node_treeview_chunck(Array.from(document.getElementsByClassName('v-treeview-allfile')[0].children), 3)
        this.scrollToBottom_v2(group_element_node_[i][group_element_node_[i].length - 1])

      

        // ## เช็ครอบของ group ว่ามี error ? อัพเดต->หยุด  : null
        if(check_error_round){
          let findIndexError = this.group_recovery.findIndex((item) => item.file_id === file_id_mistake.file_id)
          this.group_recovery[findIndexError]["file_status"] = this.$t("multirecovery.recovfail");
          if(file_id_mistake.file_type === 'folder'){
            await this.updatefilestatus_failmove(file_id_mistake.children)
          }
          this.show_all_select = true
          this.nonsuccess_loading = false 
          this.check_dis_button = false;
          break
        }

        if(chuck_group_file_id.length - 1 === i){
              this.check_dis_button = false;
              this.check_move_dis_button = true
              await this.timeout(1000)
              // ## มีไอเทมสำเร็จและรอย้าย (เปิดกู้ทั้งหมด - เปิดรอย้าย)
              if(this.count_waittomove > 0 && this.count_restoring > 0 ){
                      this.tab = 0
                      this.show_all_select = true
                      this.disable_tab_nonesuccess = false
                      this.disable_tab_success = false
                      this.checkgroup()
              }
              // ## ไม่มีไอเทมที่สำเร็จ (ปิดกู้ทั้งหมด - เปิดรอย้าย)
              else if (this.count_waittomove > 0 && this.count_restoring <= 0){
                      this.tab = 0
                      this.show_all_select = true
                      this.disable_tab_nonesuccess = false
                      this.checkgroup()
              }
              // ## ไม่มีไอเทมรอย้าย (เปิดกู้ทั้งหมด - ปิดรอย้าย)
              else if (this.count_restoring > 0 || this.count_restoreerror > 0 && this.count_waittomove <= 0  ) {
                      this.tab = 1
                      this.show_all_select = false
                      this.disable_tab_success = false
              } 
        }

      }   
    }
    catch(err){
      console.log("EER", err)
    }

    },
    async fn_restore_filefolder(itemFileFolder, payload) { // ##ไม่ได้ใช้งาน
      let auth = await gbfGenerate.generateToken();
      if(this.check_cancel){
        return new Promise ((resolve, reject) => {
          setTimeout(() => {
            reject({
              status: 'CANCEL', 
              message: "Cancel",
            })
              },500)
        }) 
      } else {
        return await this.axios
          .post(
             process.env.VUE_APP_SERVICE_DOWNLOAD_API + "/api/recovery_data_from_trash",
            //,
            payload,
            { 
              headers: { Authorization: auth.code },
            }
          )
          .then((response) => {
            // ## Response OK
            let indexUpdateMessage = this.group_recovery.findIndex((item) => item.file_id === itemFileFolder.file_id ); // ## หา Index ไอเทมที่สำเร็จ
            if (response.data.status === "OK") {
              this.group_recovery[indexUpdateMessage].file_status = this.$t("multirecovery.recovsuccess");
              this.group_recovery[indexUpdateMessage].file_status_message = response.data.message;
                return new Promise((resolve, reject) => {
                  setTimeout(() => {
                    resolve({
                      status: 'OK', 
                      message: 'Success', 
                      file_id: this.group_recovery[indexUpdateMessage].file_id,
                      file_type: this.group_recovery[indexUpdateMessage].file_type,
                      children: this.group_recovery[indexUpdateMessage].file_type === 'folder' ? 
                      this.group_recovery[indexUpdateMessage].children : [],
                      data_miss: response.data.data_miss //['0fc6f0dad5c14b7804fac9789ba232f0']
                    })
                  },1200)
                }) 
            } 
             // ## Response ER205(โฟลเดอร์ปลายทางไม่มี) ER209(โฟลเดอร์ปลายทางไม่มีสิทธิ์)
            else {
              if(response.data.errorCode === "ER205" || response.data.errorCode === "ER209" ){
                return new Promise((resolve, reject) => {
                  setTimeout(() => {
                    resolve({
                      status: 'WAIT TO MOVE', 
                      message: this.$t("multirecovery.filenoparent"),
                      file_id: this.group_recovery[indexUpdateMessage].file_id,
                      file_type: this.group_recovery[indexUpdateMessage].file_type,
                      children: this.group_recovery[indexUpdateMessage].file_type === 'folder' ? 
                      this.group_recovery[indexUpdateMessage].children : []
                    })
                  },1200)
                }) 
              } 
              // ## Response ER อื่นๆ 
              else {
                return new Promise((resolve, reject) => {
                    setTimeout(() => {
                      resolve({
                      status: 'FILE/FOLDER ERROR', 
                      message: 'Not success',
                      file_id: this.group_recovery[indexUpdateMessage].file_id,
                      file_type: this.group_recovery[indexUpdateMessage].file_type,
                      children: this.group_recovery[indexUpdateMessage].file_type === 'folder' ? 
                      this.group_recovery[indexUpdateMessage].children : []})
                    },500)
                }) 
              }
            }
          })
          // ## Response ER Axios
          .catch((error) => {
            console.log("ERROR ", error)
            let indexUpdateMessage = this.group_recovery.findIndex((item) => item.file_id === itemFileFolder.file_id ); 
            return new Promise((resolve, reject) => {
              setTimeout(() => {
                resolve({
                  status: 'AXIOS ERROR', 
                  message: this.$t("movefiles.cannotmovefile"),
                  file_id: this.group_recovery[indexUpdateMessage].file_id,
                  file_type: this.group_recovery[indexUpdateMessage].file_type,
                  children: this.group_recovery[indexUpdateMessage].file_type === 'folder' ? 
                  this.group_recovery[indexUpdateMessage].children : []
                })
              },500)
            }) 
          });
          
        
      }
    },

    async summmitrecoveryfile_v3 () { // ##ไมได้ใช้งาน V3 with Cancel and Error
      
      try{
      let chuck_group_file_id = this.group_recovery.length <= 1 ? 
      [this.group_recovery] 
      : 
      await this.file_id_chunk(this.group_recovery.filter((item)=> item.file_permission === '05' || item.file_permission === '06' ), 5)
      for(var i = 0; i < chuck_group_file_id.length; i++){ // [[123], [23]]
        var check_error_round = false
        var check_cancel_round = false
        this.cancelSource = CancelToken.source()
        let file_id_mistake
        const promises = await chuck_group_file_id[i].map(async item => {
          item.file_status = this.$t("multirecovery.recovering");

          // ## เก็บ file_id ของโฟลเดอรที่มีลูก
          var finally_sub_fileId
              let temp_folder = []
              let temp_file = []
              finally_sub_fileId = await this.depressed_subfilefolder_id(
              item.file_type === 'folder' ? item.children : [], temp_folder, temp_file)
          
         let payload = {
              account_id: this.file.find((itemFind) => itemFind.file_id === item.file_id).account_sender,
              user_id: this.dataUsername,
              data_id: item.file_id,
              data_type: item.file_type === "folder" ? "folder" : "file",
              sub_folder: finally_sub_fileId.tempfolder,
              sub_file: finally_sub_fileId.tempfile
            }

          const responseRecovery =  await this.fn_restore_filefolder_v2 (item, payload)
          return responseRecovery
        })
        await Promise.all(promises)
        .then(async(AllRes) => {
          for(const itemFile of AllRes){
            console.log("Item ", AllRes)
            if(itemFile.status === 'OK'){
              this.count_restoring ++
              this.success_rocovery.push(this.group_recovery.find((item) => item.file_id === itemFile.file_id ))
              if(itemFile.file_type === 'folder'){ // #อัพเดต ไฟล์หรือโฟลเดอร์ตัวลูกๆ
                let countmistake = 0
                //await this.updatefilestatus(itemFile.children, itemFile.data_miss, countmistake)

                //เพิ่ม snipped
                if(await this.updatefilestatus(itemFile.children, itemFile.data_miss, countmistake) > 0){ // ## เช็ค datamiss ที่ส่งมาบาง fileid ของลูกๆนั้น
                    let findIndexItem = this.group_recovery.findIndex((item) => item.file_id === itemFile.file_id)
                    this.group_recovery[findIndexItem]["message"] = 'บางไฟล์/โฟลเดอร์กู้คืนไม่สำเร็จ'
                  }
              }
            } 
            else if (itemFile.status === 'WAIT TO MOVE') {
              try{
                this.count_waittomove++
                let findIndexError = this.group_recovery.findIndex((item) => item.file_id === itemFile.file_id)
                this.group_recovery[findIndexError]["file_status"] = this.$t("multirecovery.waittomove");

                // ## เก็บ file หรือ folder แบบ treeview และอัพเดตตัวลูก (แบบใหม่)
                if(this.group_recovery[findIndexError] !== 'folder'){
                  // this.nonesuccess_recovery.push(this.group_recovery[i]) // Old
                  this.nonsuccess_group_recovery.push(this.group_recovery[findIndexError])
                } else{
                  await this.updatefilestatus_waittomove(this.group_recovery[findIndexError].children)
                  this.nonsuccess_group_recovery.push(this.group_recovery[findIndexError])
                }
              } catch (err) {
                console.log("ERRORR ", err)
              }

            } 
            else if (itemFile.status === 'FILE/FOLDER ERROR') {
                let findIndexError = this.group_recovery.findIndex((item) => item.file_id === itemFile.file_id)
                this.group_recovery[findIndexError]["file_status"] = this.$t("multirecovery.recovfail");
                if(this.group_recovery[i].file_type === 'folder'){
                  await this.updatefilestatus_failmove(itemFile.children)
                }
            } 
            else if (itemFile.status === 'AXIOS ERROR') {
              check_error_round = true
            } else {
              // check_cancel_round = true
              console.log("Cancel ")
            }
          }
        })
        .catch((err) => {
          check_cancel_round = true
          console.log("Cancel Error ")
        })


        let group_element_node_ = await this.slice_node_treeview_chunck(Array.from(document.getElementsByClassName('v-treeview-allfile')[0].children), 5)
        this.scrollToBottom_v2(group_element_node_[i][group_element_node_[i].length - 1])

      

        // ## เช็ครอบของ group ว่ามี error หรือ cancel  ? อัพเดต->หยุด  : null
        if(check_error_round || check_cancel_round){
          chuck_group_file_id[i].forEach(async (item) => {
            if(item.file_status === this.$t("multirecovery.recovering")){
              item.file_status = check_cancel_round ? this.$t('multirecovery.recovery_cancel') :  this.$t("multirecovery.recovfail")
              if(item.file_type === 'folder'){
                await this.updatefilestatus_failmove(item.children)
              }
            }
          })
          //this.show_all_select = true
          this.nonsuccess_loading = false 
          this.check_dis_button = false;
          this.check_move_dis_button = true
          break
        }


        if(chuck_group_file_id.length - 1 === i){
          console.log("Finish State")
          this.check_dis_button = false;
          this.check_move_dis_button = true
          await this.timeout(600)
          // ## มีไอเทมสำเร็จและรอย้าย (เปิดกู้ทั้งหมด - เปิดรอย้าย)
          if(this.count_waittomove > 0 && this.count_restoring > 0 ){
              this.tab = 0
              this.show_all_select = true
              this.disable_tab_nonesuccess = false
              this.disable_tab_success = false
              this.checkgroup()
          }
          // ## ไม่มีไอเทมที่สำเร็จ (ปิดกู้ทั้งหมด - เปิดรอย้าย)
          else if (this.count_waittomove > 0 && this.count_restoring <= 0){
              this.tab = 0
              this.show_all_select = true
              this.disable_tab_nonesuccess = false
              this.checkgroup()
          }
          // ## ไม่มีไอเทมรอย้าย (เปิดกู้ทั้งหมด - ปิดรอย้าย)
          else if (this.count_restoring > 0 || this.count_restoreerror > 0 && this.count_waittomove <= 0  ) {
                      this.tab = 1
                      this.show_all_select = false
                      this.disable_tab_success = false
          } 
        }

      } 
      
      if(check_cancel_round || check_error_round){
        await this.timeout(600)
          // ## มีไอเทมสำเร็จและรอย้าย (เปิดกู้ทั้งหมด - เปิดรอย้าย)
          if(this.count_waittomove > 0 && this.count_restoring > 0 ){
                      this.tab = 0
                      this.show_all_select = true
                      this.disable_tab_nonesuccess = false
                      this.disable_tab_success = false
                      this.checkgroup()
          }
          // ## ไม่มีไอเทมที่สำเร็จ (ปิดกู้ทั้งหมด - เปิดรอย้าย)
          else if (this.count_waittomove > 0 && this.count_restoring <= 0){
                      this.tab = 0
                      this.show_all_select = true
                      this.disable_tab_nonesuccess = false
                      this.checkgroup()
          }
          // ## ไม่มีไอเทมรอย้าย (เปิดกู้ทั้งหมด - ปิดรอย้าย)
          else if (this.count_restoring > 0 || this.count_restoreerror > 0 && this.count_waittomove <= 0  ) {
                      this.tab = 1
                      this.show_all_select = false
                      this.disable_tab_success = false
          } 
      }
    }
    catch(err){
      console.log("EER", err)
    }

    },
    async fn_restore_filefolder_v2(itemFileFolder, payload) { // ##ไมได้ใช้งาน
      let auth = await gbfGenerate.generateToken();
      return await this.axios
          .post(
              //itemFileFolder.file_name === 'TEST_uploads' || itemFileFolder.file_name === 'New_Recovry_Child_1 - Copy - Copy - Copy' ? "http://192.168.72.137:8812/api/recovery_data_from_trash" :
              process.env.VUE_APP_SERVICE_DOWNLOAD_API + "/api/recovery_data_from_trash",
              payload,
              { 
                headers: { Authorization: auth.code },
                cancelToken: this.cancelSource.token,
              }
          )
          .then((response) => {
            // ## Response OK
            let indexUpdateMessage = this.group_recovery.findIndex((item) => item.file_id === itemFileFolder.file_id ); // ## หา Index ไอเทมที่สำเร็จ
            if (response.data.status === "OK") {
              this.group_recovery[indexUpdateMessage].file_status = this.$t("multirecovery.recovsuccess");
              this.group_recovery[indexUpdateMessage].file_status_message = response.data.message;
                return new Promise((resolve, reject) => {
                  resolve({
                      status: 'OK', 
                      message: 'Success', 
                      file_id: this.group_recovery[indexUpdateMessage].file_id,
                      file_type: this.group_recovery[indexUpdateMessage].file_type,
                      children: this.group_recovery[indexUpdateMessage].file_type === 'folder' ? 
                      this.group_recovery[indexUpdateMessage].children : [],
                      data_miss: response.data.data_miss //['0fc6f0dad5c14b7804fac9789ba232f0']
                    })
                }) 
            } 
             // ## Response ER205(โฟลเดอร์ปลายทางไม่มี) ER209(โฟลเดอร์ปลายทางไม่มีสิทธิ์)
            else {
              if(response.data.errorCode === "ER205" || response.data.errorCode === "ER209" ){
                return new Promise((resolve, reject) => {
                  setTimeout(() => {
                    resolve({
                      status: 'WAIT TO MOVE', 
                      message: this.$t("multirecovery.filenoparent"),
                      file_id: this.group_recovery[indexUpdateMessage].file_id,
                      file_type: this.group_recovery[indexUpdateMessage].file_type,
                      children: this.group_recovery[indexUpdateMessage].file_type === 'folder' ? 
                      this.group_recovery[indexUpdateMessage].children : []
                    })
                  },500)
                }) 
              } 
              // ## Response ER อื่นๆ 
              else {
                return new Promise((resolve, reject) => {
                    setTimeout(() => {
                      resolve({
                      status: 'FILE/FOLDER ERROR', 
                      message: 'Not success',
                      file_id: this.group_recovery[indexUpdateMessage].file_id,
                      file_type: this.group_recovery[indexUpdateMessage].file_type,
                      children: this.group_recovery[indexUpdateMessage].file_type === 'folder' ? 
                      this.group_recovery[indexUpdateMessage].children : []})
                    },500)
                }) 
              }
            }
          })
          // ## Response ER Axios OR Cancel Token
          .catch((error) => {
            let indexUpdateMessage = this.group_recovery.findIndex((item) => item.file_id === itemFileFolder.file_id ); 
            if(this.axios.isCancel(error)){
              console.log("Cancel ehhere ")
              return new Promise((resolve, reject) => {
                reject({
                      status: "CANCEL", 
                      message: "All Cancel",
                      file_id: this.group_recovery[indexUpdateMessage].file_id,
                      file_type: this.group_recovery[indexUpdateMessage].file_type,
                      children: this.group_recovery[indexUpdateMessage].file_type === 'folder' ? 
                      this.group_recovery[indexUpdateMessage].children : []
                    }) 
                })
            } else {
              return new Promise((resolve, reject) => {
              setTimeout(() => {
                resolve({
                  status: 'AXIOS ERROR', 
                  message: this.$t("movefiles.cannotmovefile"),
                  file_id: this.group_recovery[indexUpdateMessage].file_id,
                  file_type: this.group_recovery[indexUpdateMessage].file_type,
                  children: this.group_recovery[indexUpdateMessage].file_type === 'folder' ? 
                  this.group_recovery[indexUpdateMessage].children : []
                })
              },500)
            }) 
            }
            
          });
          
    },

    async summmitrecoveryfile_v4 () { // ## V4
      try{
      let chuck_group_file_id = this.group_recovery.length <= 1 ? 
      [this.group_recovery] 
      : 
      await this.file_id_chunk(this.group_recovery.filter((item)=> item.file_permission === '05' || item.file_permission === '06' ), 5)
      for(var i = 0; i < chuck_group_file_id.length; i++){ // [[123], [23]]
        let check_error_round = false
        var check_cancel_round = false
        let file_id_mistake
        if(this.cancelCheck){
          console.log("Working Immidiat")
          this.group_recovery.filter((item) => item.file_status === this.$t("multirecovery.allowpermission")).forEach(async (item) => {
            item.file_status =  this.$t('multirecovery.recovery_cancel') 
              if(item.file_type === 'folder'){
                await this.updatefilestatus_failmove(item.children)
              }
          })
          this.nonsuccess_loading = false 
          this.check_dis_button = false;
          this.check_move_dis_button = true
          break
        }
        const promises = await chuck_group_file_id[i].map(async item => {
          item.file_status = this.$t("multirecovery.recovering");

          // ## เก็บ file_id ของโฟลเดอรที่มีลูก
          var finally_sub_fileId
              let temp_folder = []
              let temp_file = []
              finally_sub_fileId = await this.depressed_subfilefolder_id(
              item.file_type === 'folder' ? item.children : [], temp_folder, temp_file)
          
         let payload = {
              account_id: this.file.find((itemFind) => itemFind.file_id === item.file_id).account_sender,
              user_id: this.dataUsername,
              data_id: item.file_id,
              data_type: item.file_type === "folder" ? "folder" : "file",
              sub_folder: finally_sub_fileId.tempfolder,
              sub_file: finally_sub_fileId.tempfile
            }

          const responseRecovery =  await this.fn_restore_filefolder_v3 (item, payload)
          return responseRecovery
        })
        await Promise.all(promises)
        .then(async(AllRes) => {
          for(const itemFile of AllRes){
            if(itemFile.status === 'OK'){
              this.count_restoring ++
              this.success_rocovery.push(this.group_recovery.find((item) => item.file_id === itemFile.file_id ))
              if(itemFile.file_type === 'folder'){ // #อัพเดต ไฟล์หรือโฟลเดอร์ตัวลูกๆ
                let countmistake = 0
                //await this.updatefilestatus(itemFile.children, itemFile.data_miss, countmistake)

                //เพิ่ม snipped
                if(await this.updatefilestatus(itemFile.children, itemFile.data_miss, countmistake) > 0){ // ## เช็ค datamiss ที่ส่งมาบาง fileid ของลูกๆนั้น
                    let findIndexItem = this.group_recovery.findIndex((item) => item.file_id === itemFile.file_id)
                    this.group_recovery[findIndexItem]["message"] = 'บางไฟล์/โฟลเดอร์กู้คืนไม่สำเร็จ'
                  }
              }
            } 
            else if (itemFile.status === 'WAIT TO MOVE') {
              try{
                this.count_waittomove++
                let findIndexError = this.group_recovery.findIndex((item) => item.file_id === itemFile.file_id)
                this.group_recovery[findIndexError]["file_status"] = this.$t("multirecovery.waittomove");

                // ## เก็บ file หรือ folder แบบ treeview และอัพเดตตัวลูก (แบบใหม่)
                if(this.group_recovery[findIndexError] !== 'folder'){
                  // this.nonesuccess_recovery.push(this.group_recovery[i]) // Old
                  this.nonsuccess_group_recovery.push(this.group_recovery[findIndexError])
                } else{
                  await this.updatefilestatus_waittomove(this.group_recovery[findIndexError].children)
                  this.nonsuccess_group_recovery.push(this.group_recovery[findIndexError])
                }
              } catch (err) {
                console.log("ERRORR ", err)
              }

            } 
            else if (itemFile.status === 'FILE/FOLDER ERROR') {
                let findIndexError = this.group_recovery.findIndex((item) => item.file_id === itemFile.file_id)
                this.group_recovery[findIndexError]["file_status"] = this.$t("multirecovery.recovfail");
                if(this.group_recovery[i].file_type === 'folder'){
                  await this.updatefilestatus_failmove(itemFile.children)
                }
              } 
            else {
              check_error_round = true
              file_id_mistake = itemFile
            }
          }
        })
        let group_element_node_ = await this.slice_node_treeview_chunck(Array.from(document.getElementsByClassName('v-treeview-allfile')[0].children), 5)
        this.scrollToBottom_v2(group_element_node_[i][group_element_node_[i].length - 1])
        // let sum = 0
        // group_element_node_[i].forEach((item) => sum += item.offsetHeight )
        // this.heightGroupItem += sum
        // this.scrollToBottom_v3(this.heightGroupItem) //group_element_node_[i].length - 1

       

        // ## เช็ครอบของ group ว่ามี error ? อัพเดต->หยุด  : null
        if(check_error_round){
          let findIndexError = this.group_recovery.findIndex((item) => item.file_id === file_id_mistake.file_id)
          this.group_recovery[findIndexError]["file_status"] = this.$t("multirecovery.recovfail");
          if(file_id_mistake.file_type === 'folder'){
            await this.updatefilestatus_failmove(file_id_mistake.children)
          }
          this.show_all_select = true
          this.nonsuccess_loading = false 
          this.check_dis_button = false;
          break
        }

        if(chuck_group_file_id.length - 1 === i){
              this.check_dis_button = false;
              this.check_move_dis_button = true
              await this.timeout(1000)
              // ## มีไอเทมสำเร็จและรอย้าย (เปิดกู้ทั้งหมด - เปิดรอย้าย)
              if(this.count_waittomove > 0 && this.count_restoring > 0 ){
                      this.tab = 0
                      this.show_all_select = true
                      this.disable_tab_nonesuccess = false
                      this.disable_tab_success = false
                      this.checkgroup()
              }
              // ## ไม่มีไอเทมที่สำเร็จ (ปิดกู้ทั้งหมด - เปิดรอย้าย)
              else if (this.count_waittomove > 0 && this.count_restoring <= 0){
                      this.tab = 0
                      this.show_all_select = true
                      this.disable_tab_nonesuccess = false
                      this.checkgroup()
              }
              // ## ไม่มีไอเทมรอย้าย (เปิดกู้ทั้งหมด - ปิดรอย้าย)
              else if (this.count_restoring > 0 || this.count_restoreerror > 0 && this.count_waittomove <= 0  ) {
                      this.tab = 1
                      this.show_all_select = false
                      this.disable_tab_success = false
              } 
        }
      }   

      if(this.cancelCheck){
        await this.timeout(600)
          // ## มีไอเทมสำเร็จและรอย้าย (เปิดกู้ทั้งหมด - เปิดรอย้าย)
          if(this.count_waittomove > 0 && this.count_restoring > 0 ){
                      this.tab = 0
                      this.show_all_select = true
                      this.disable_tab_nonesuccess = false
                      this.disable_tab_success = false
                      this.checkgroup()
          }
          // ## ไม่มีไอเทมที่สำเร็จ (ปิดกู้ทั้งหมด - เปิดรอย้าย)
          else if (this.count_waittomove > 0 && this.count_restoring <= 0){
                      this.tab = 0
                      this.show_all_select = true
                      this.disable_tab_nonesuccess = false
                      this.checkgroup()
          }
          // ## ไม่มีไอเทมรอย้าย (เปิดกู้ทั้งหมด - ปิดรอย้าย)
          else if (this.count_restoring > 0 || this.count_restoreerror > 0 && this.count_waittomove <= 0  ) {
                      this.tab = 1
                      this.show_all_select = false
                      this.disable_tab_success = false
          } 
      }
      

    }
    catch(err){
      console.log("EER", err)
    }

    },
    async fn_check_duplicate (itemFileFolder, payload) {
      let auth = await gbfGenerate.generateToken();
      try {
        let response = await this.axios({
          method: "POST",
          url:
            process.env.VUE_APP_SERVICE_UPLOAD_API +
            "/api/v1/check_duplicate_data",
          data: payload,
          headers: { Authorization: auth.code },
        });

        let indexUpdateMessage = this.group_recovery.findIndex((item) => item.file_id === itemFileFolder.file_id ); // ## หา Index ไอเทมที่สำเร็จ
        // this.group_recovery[indexUpdateMessage].file_status = "duplicate"
        // this.group_recovery[indexUpdateMessage].file_status_message = "duplicate najah"
        if (response.data.status === "OK") {
          if (response.data.message === "duplicate filename" || response.data.message === "duplicate foldername") {
            this.count_duplicate ++ 
            console.log("thisss groupp ", this.group_recovery)
            this.group_recovery[indexUpdateMessage].file_status = "duplicate"
          this.group_recovery[indexUpdateMessage].file_status_message = "duplicate najah"
          this.duplicate_file_recovery.push(itemFileFolder)
            return new Promise(async (resolve, reject) => {
              setTimeout(() => {
                resolve({
                  fileitem: itemFileFolder,
                  status: "duplicate",
                  msg: response.data.message,
                });
              }, 500)
            });
          } else {
            return new Promise(async (resolve, reject) => {
              setTimeout(() => {
                resolve({
                  fileitem: itemFileFolder,
                  status: "no duplicate",
                  msg: response.data.message,
                });
              }, 500)
            });
          }
        } else {
          return new Promise(async (resolve, reject) => {
            setTimeout(() => {
                resolve({
                  fileitem: itemFileFolder,
                  status: "error",
                  msg: response.data.message,
                });
            }, 500)
          });
        }
      } catch (err) {
        return new Promise(async (resolve, reject) => {
          resolve({
            fileitem: itemFileFolder,
            status: "upload denied",
            msg: err,
          });
        });
      }
    },

    async fn_restore_filefolder_v3(itemFileFolder, payload) { // ##
      let auth = await gbfGenerate.generateToken();
      return await this.axios
          .post(
            process.env.VUE_APP_SERVICE_DOWNLOAD_API + 
            "/api/v2/recovery_data_from_trash",
            //,
            payload,
            { 
              headers: { Authorization: auth.code },
            }
          )
          .then((response) => {
            // ## Response OK
            let indexUpdateMessage = this.group_recovery.findIndex((item) => item.file_id === itemFileFolder.file_id ); // ## หา Index ไอเทมที่สำเร็จ
            if (response.data.status === "OK") {
              this.group_recovery[indexUpdateMessage].file_status = this.$t("multirecovery.recovsuccess");
              this.group_recovery[indexUpdateMessage].file_status_message = response.data.message;
                return new Promise((resolve, reject) => {
                  setTimeout(() => {
                    resolve({
                      status: 'OK', 
                      message: 'Success', 
                      file_id: this.group_recovery[indexUpdateMessage].file_id,
                      file_type: this.group_recovery[indexUpdateMessage].file_type,
                      children: this.group_recovery[indexUpdateMessage].file_type === 'folder' ? 
                      this.group_recovery[indexUpdateMessage].children : [],
                      data_miss: response.data.data_miss //['0fc6f0dad5c14b7804fac9789ba232f0']
                    })
                  },500)
                }) 
            } 
             // ## Response ER205(โฟลเดอร์ปลายทางไม่มี) ER209(โฟลเดอร์ปลายทางไม่มีสิทธิ์)
            else {
              if(response.data.errorCode === "ER205" || response.data.errorCode === "ER209" ){
                return new Promise((resolve, reject) => {
                  setTimeout(() => {
                    resolve({
                      status: 'WAIT TO MOVE', 
                      message: this.$t("multirecovery.filenoparent"),
                      file_id: this.group_recovery[indexUpdateMessage].file_id,
                      file_type: this.group_recovery[indexUpdateMessage].file_type,
                      children: this.group_recovery[indexUpdateMessage].file_type === 'folder' ? 
                      this.group_recovery[indexUpdateMessage].children : []
                    })
                  },500)
                }) 
              } 
              // ## Response ER อื่นๆ 
              else {
                return new Promise((resolve, reject) => {
                    setTimeout(() => {
                      resolve({
                      status: 'FILE/FOLDER ERROR', 
                      message: 'Not success',
                      file_id: this.group_recovery[indexUpdateMessage].file_id,
                      file_type: this.group_recovery[indexUpdateMessage].file_type,
                      children: this.group_recovery[indexUpdateMessage].file_type === 'folder' ? 
                      this.group_recovery[indexUpdateMessage].children : []})
                    },500)
                }) 
              }
            }
          })
          // ## Response ER Axios
          .catch((error) => {
            console.log("ERROR ", error)
            let indexUpdateMessage = this.group_recovery.findIndex((item) => item.file_id === itemFileFolder.file_id ); 
            return new Promise((resolve, reject) => {
              setTimeout(() => {
                resolve({
                  status: 'AXIOS ERROR', 
                  message: this.$t("movefiles.cannotmovefile"),
                  file_id: this.group_recovery[indexUpdateMessage].file_id,
                  file_type: this.group_recovery[indexUpdateMessage].file_type,
                  children: this.group_recovery[indexUpdateMessage].file_type === 'folder' ? 
                  this.group_recovery[indexUpdateMessage].children : []
                })
              },500)
            }) 
          });
    },


    async summmitrecoveryfile_v5 (type) { // ## V4
      try{
        let chuck_group_file_id
        chuck_group_file_id = this.group_recovery.length <= 1 ? 
        [this.group_recovery] 
        : 
        await this.file_id_chunk(this.group_recovery.filter((item)=> item.file_permission === '05' || item.file_permission === '06' ), 5)

      // let chuck_group_file_id = this.group_recovery.length <= 1 ? 
      // [this.group_recovery] 
      // : 
      // await this.file_id_chunk(this.group_recovery.filter((item)=> item.file_permission === '05' || item.file_permission === '06' ), 5)
      console.log("chunck gruppp ", chuck_group_file_id)
      for(var i = 0; i < chuck_group_file_id.length; i++){ // [[123], [23]]
        let check_error_round = false
        var check_cancel_round = false
        let file_id_mistake
        if(this.cancelCheck){
          console.log("Working Immidiat")
          this.group_recovery.filter((item) => item.file_status === this.$t("multirecovery.allowpermission")).forEach(async (item) => {
            item.file_status =  this.$t('multirecovery.recovery_cancel') 
              if(item.file_type === 'folder'){
                await this.updatefilestatus_failmove(item.children)
              }
          })
          this.nonsuccess_loading = false 
          this.check_dis_button = false;
          this.check_move_dis_button = true
          break
        }
        // ตรวจสอบ type ก่อนน
        
        
        //console.log("HELLO WORDD ", chuck_group_file_id[i].filter((item) => { if (type === 'first') { return item.file_status === 'duplicate' } else { return item.file_status !== 'duplicate'  } }))
        // ทำการ check duplicate ก่อนนน
        let promise_checkdup = await chuck_group_file_id[i].map(async item => { 
          item.file_status = this.$t("multirecovery.recovering");
          let payload_duplicate = {
            folder_id: item.folder_id,
            data_type: item.type,
            data_name: item.name,
          };

          const responseCheckdup = await this.fn_check_duplicate (item, payload_duplicate)
          return responseCheckdup

        })
        await Promise.all(promise_checkdup)
        .then(async (response) => {
          const promises = await chuck_group_file_id[i].filter((item) => item.file_status !== 'duplicate').map(async item => {
            // ## เก็บ file_id ของโฟลเดอรที่มีลูก
            var finally_sub_fileId
                let temp_folder = []
                let temp_file = []
                finally_sub_fileId = await this.depressed_subfilefolder_id(
                item.file_type === 'folder' ? item.children : [], temp_folder, temp_file)
            
           let payload = {
                account_id: this.file.find((itemFind) => itemFind.file_id === item.file_id).account_sender,
                user_id: this.dataUsername,
                data_id: item.file_id,
                data_type: item.file_type === "folder" ? "folder" : "file",
                sub_folder: finally_sub_fileId.tempfolder,
                sub_file: finally_sub_fileId.tempfile,
                duplicate_status: false,
                new_name: ""
              }

            const responseRecovery =  await this.fn_restore_filefolder_v3 (item, payload)
            return responseRecovery
          })
          await Promise.all(promises)
          .then(async (response) => {
               for(const itemFile of response){
                if(itemFile.status === 'OK'){
                  this.count_restoring ++
                  this.success_rocovery.push(this.group_recovery.find((item) => item.file_id === itemFile.file_id ))
                  if(itemFile.file_type === 'folder'){ // #อัพเดต ไฟล์หรือโฟลเดอร์ตัวลูกๆ
                    let countmistake = 0
                    //await this.updatefilestatus(itemFile.children, itemFile.data_miss, countmistake)

                    //เพิ่ม snipped
                    if(await this.updatefilestatus(itemFile.children, itemFile.data_miss, countmistake) > 0){ // ## เช็ค datamiss ที่ส่งมาบาง fileid ของลูกๆนั้น
                        let findIndexItem = this.group_recovery.findIndex((item) => item.file_id === itemFile.file_id)
                        this.group_recovery[findIndexItem]["message"] = 'บางไฟล์/โฟลเดอร์กู้คืนไม่สำเร็จ'
                      }
                  }
                } 
                else if (itemFile.status === 'WAIT TO MOVE') {
                  try{
                    this.count_waittomove++
                    let findIndexError = this.group_recovery.findIndex((item) => item.file_id === itemFile.file_id)
                    this.group_recovery[findIndexError]["file_status"] = this.$t("multirecovery.waittomove");

                    // ## เก็บ file หรือ folder แบบ treeview และอัพเดตตัวลูก (แบบใหม่)
                    if(this.group_recovery[findIndexError] !== 'folder'){
                      // this.nonesuccess_recovery.push(this.group_recovery[i]) // Old
                      this.nonsuccess_group_recovery.push(this.group_recovery[findIndexError])
                    } else{
                      await this.updatefilestatus_waittomove(this.group_recovery[findIndexError].children)
                      this.nonsuccess_group_recovery.push(this.group_recovery[findIndexError])
                    }
                  } catch (err) {
                    console.log("ERRORR ", err)
                  }

                } 
                else if (itemFile.status === 'FILE/FOLDER ERROR') {
                    let findIndexError = this.group_recovery.findIndex((item) => item.file_id === itemFile.file_id)
                    this.group_recovery[findIndexError]["file_status"] = this.$t("multirecovery.recovfail");
                    if(this.group_recovery[i].file_type === 'folder'){
                      await this.updatefilestatus_failmove(itemFile.children)
                    }
                } 
                else {
                  check_error_round = true
                  file_id_mistake = itemFile
                }
              }


          })



        })
        let group_element_node_ = await this.slice_node_treeview_chunck(Array.from(document.getElementsByClassName('v-treeview-allfile')[0].children), 5)
        this.scrollToBottom_v2(group_element_node_[i][group_element_node_[i].length - 1])
    
        // ## เช็ครอบของ group ว่ามี error ? อัพเดต->หยุด  : null
        if(check_error_round){
          let findIndexError = this.group_recovery.findIndex((item) => item.file_id === file_id_mistake.file_id)
          this.group_recovery[findIndexError]["file_status"] = this.$t("multirecovery.recovfail");
          if(file_id_mistake.file_type === 'folder'){
            await this.updatefilestatus_failmove(file_id_mistake.children)
          }
          this.show_all_select = true
          this.nonsuccess_loading = false 
          this.check_dis_button = false;
          break
        }

        if(chuck_group_file_id.length - 1 === i){
              this.check_dis_button = false;
              this.check_move_dis_button = true
              await this.timeout(1000)
              // ## มีไอเทมสำเร็จและรอย้าย (เปิดกู้ทั้งหมด - เปิดรอย้าย)
              if(this.count_waittomove > 0 && this.count_restoring > 0 ){
                      this.tab = 0
                      this.show_all_select = true
                      this.disable_tab_nonesuccess = false
                      this.disable_tab_success = false
                      this.checkgroup()
              }
              // ## ไม่มีไอเทมที่สำเร็จ (ปิดกู้ทั้งหมด - เปิดรอย้าย)
              else if (this.count_waittomove > 0 && this.count_restoring <= 0){
                      this.tab = 0
                      this.show_all_select = true
                      this.disable_tab_nonesuccess = false
                      this.checkgroup()
              }
              // ## ไม่มีไอเทมรอย้าย (เปิดกู้ทั้งหมด - ปิดรอย้าย)
              else if (this.count_restoring > 0 || this.count_restoreerror > 0 && this.count_waittomove <= 0  ) {
                      this.tab = 1
                      this.show_all_select = false
                      this.disable_tab_success = false
              }

              if (this.count_duplicate > 0) {
                this.duplicate_file_recovery =  this.duplicate_file_recovery.map((item) => {
                  return {
                    ...item,
                    namemodel: item.type === 'folder' ? item.file_name : item.file_name.replace(/\.[^/.]+$/, ''),
                    backup_name: item.file_name,
                    status_change_name: false,
                    errorMessage: "",
                    restore_copy: false,
                    state_change: false,

                  }
                })
                console.log("duppppp ", this.duplicate_file_recovery);
                // SORT ให้ folder ขึ้น
                this.du

                setTimeout(() => {
                  this.open_dialog_manageDup = true
                }, 500)
                
              }
        }
      }   

      if(this.cancelCheck){
        await this.timeout(600)
          // ## มีไอเทมสำเร็จและรอย้าย (เปิดกู้ทั้งหมด - เปิดรอย้าย)
          if(this.count_waittomove > 0 && this.count_restoring > 0 ){
                      this.tab = 0
                      this.show_all_select = true
                      this.disable_tab_nonesuccess = false
                      this.disable_tab_success = false
                      this.checkgroup()
          }
          // ## ไม่มีไอเทมที่สำเร็จ (ปิดกู้ทั้งหมด - เปิดรอย้าย)
          else if (this.count_waittomove > 0 && this.count_restoring <= 0){
                      this.tab = 0
                      this.show_all_select = true
                      this.disable_tab_nonesuccess = false
                      this.checkgroup()
          }
          // ## ไม่มีไอเทมรอย้าย (เปิดกู้ทั้งหมด - ปิดรอย้าย)
          else if (this.count_restoring > 0 || this.count_restoreerror > 0 && this.count_waittomove <= 0  ) {
                      this.tab = 1
                      this.show_all_select = false
                      this.disable_tab_success = false
          } 
      }
      

    }
    catch(err){
      console.log("EER", err)
    }

    },
    
    ////////////////////////////////////
    
    // MOVE FILE FOLDER METHOD //

    async summitmovefile() { // ## ไม่ได้ใช้งาน
      var payload;
      let arrayIdBaseOn = this.nonsuccess_group_recovery.map((item) => item.file_id) // [A, B, C]
      this.multiitemwaitmove_v2 = this.multiitemwaitmove_v2.sort(function (a, b) {
        return arrayIdBaseOn.indexOf(a.file_id) - arrayIdBaseOn.indexOf(b.file_id);
      })
      for(var i = 0; i < this.multiitemwaitmove_v2.length; i++){ // [{a, {b, c}}, {d, {e, f}}]
          this.openIds = []
          this.openIds.push(this.multiitemwaitmove_v2[i])
          this.disable_treeview_loading = true
          this.multiitemwaitmove_v2[i].file_status = this.$t("multirecovery.movingfilefolder");
          // FOR TEST WITHOUT API
          // await this.timeout(2000)
          // this.multiitemwaitmove_v2[i].file_status = this.$t("multirecovery.recovsuccess");
          // this.success_rocovery.push(this.multiitemwaitmove_v2[i])

          // if(this.multiitemwaitmove_v2[i].file_type === 'folder'){
          //     // this.updatefilestatus(this.multiitemwaitmove_v2[i].children)
          //     let countmistake = 0
          //     if(await this.updatefilestatus(this.multiitemwaitmove_v2[i].children, someproblemKey, countmistake) > 0){
          //       this.multiitemwaitmove_v2[i]["message"] = 'บางไฟล์/โฟลเดอร์กู้คืนไม่สำเร็จ'
          //     }
          // }
          ///////////////////////////////

          payload = {
            data_id: this.multiitemwaitmove_v2[i].file_id,
            data_type: this.multiitemwaitmove_v2[i].file_type === "folder" ? "folder" : "file",
            account_id: this.dataAccountId,
            folder_id_move_to: this.filefoldermoveto,
            user_id: this.dataUsername
          };
        
          let resultQueryfile = await this.fn_move_filefolder(payload) 
        
          if(resultQueryfile.status === 'OK'){
            this.success_rocovery.push(this.multiitemwaitmove_v2[i])
            if(this.multiitemwaitmove_v2[i].file_type === 'folder'){
              let countmistake = 0
              if(await this.updatefilestatus(this.multiitemwaitmove_v2[i].children, resultQueryfile.data_miss, countmistake) > 0){
                this.multiitemwaitmove_v2[i]["message"] = 'บางไฟล์/โฟลเดอร์กู้คืนไม่สำเร็จ'
              }
            }
          } else if (resultQueryfile.status === 'FILE/FOLDER ERROR') {
            this.multiitemwaitmove_v2[i]["file_status"] = this.$t("multirecovery.recovfail");
            if(this.multiitemwaitmove_v2[i].file_type === 'folder'){
              await this.updatefilestatus_failmove(this.multiitemwaitmove_v2[i].children)
            }
          } else {
            this.multiitemwaitmove_v2[i]["file_status"] = this.$t("multirecovery.recovfail");
            this.show_all_select = true
            this.nonsuccess_loading = false 
            this.check_dis_button = false;
            break
          }

          if(this.multiitemwaitmove_v2.length - 1 === i){
            this.openIds = []
            await this.timeout(600)
            this.disable_tab_success = this.success_rocovery.length > 0 ? false : true
            this.checkAll = false 
            this.multiitemwaitmove_v2 = []
            this.disable_treeview_loading = false
            this.$emit('clearpathmoveto')
            console.log("Nonsuccess grouo ", this.nonsuccess_group_recovery)
            if(this.nonsuccess_group_recovery.filter((item) => item.file_status === 'Wait to move' || item.file_status === 'รอย้ายไฟล์').length <= 0){
              // this.tab = 1
              // this.disable_tab_nonesuccess = true
              this.show_all_select = false
            }
          }

      }
    },

    async fn_move_filefolder (payload) { // ## ไม่ได้ใช้งาน
      let auth = await gbfGenerate.generateToken();
        return await this.axios
          .post(
            process.env.VUE_APP_SERVICE_DOWNLOAD_API + "/api/move_data_from_trash",
            payload,
            { headers: { Authorization: auth.code } }
          )
          .then((response) => {
            if (response.data.status === "OK") {
              let id_item = payload.data_id;
              let indexUpdateMessage = this.multiitemwaitmove_v2.findIndex((item) => item.file_id === id_item);
              this.multiitemwaitmove_v2[indexUpdateMessage].file_status = this.$t("multirecovery.recovsuccess");
              this.multiitemwaitmove_v2[indexUpdateMessage].file_status_message = response.data.message;
              return new Promise((resolve, reject) => {
                setTimeout(() => {
                    resolve({status: 'OK', message: 'Success', data_miss: ['aae7e61c8cd289fa4dde5ea196ed0656', 'bf279455ebb651734370196879a96af6']})
                },500)
              }) 

            } else {
              return new Promise((resolve, reject) => {
                  setTimeout(() => {
                    resolve({status: 'FILE/FOLDER ERROR', message: 'Not success'})
                  },500)
              }) 
            }
          })
          .catch((error) => {
            console.log("Error ", error)
            return new Promise((resolve, reject) => {
              setTimeout(() => {
                resolve({status: 'AXIOS ERROR', message: this.$t("movefiles.cannotmovefile")})
              },500)
            }) 
          });
    },

   

    // MOVE FILE FOLDER V2 (Use Promise All, with many request) METHOD //
    
    // ## จัด group ไฟลโฟลเดอ
    async file_id_chunk (file_id, per_chunck) { 
    const result = file_id.reduce((resultArray, item, index) => { 
      const chunkIndex = Math.floor(index/per_chunck)
      if(!resultArray[chunkIndex]) {
        resultArray[chunkIndex] = [] // start a new chunk
      }
      resultArray[chunkIndex].push(item)
      return resultArray
      }, [])

     return result
    },

    // ## จัด Element node treeview ตาม group ไฟลโฟลเดอ
    async slice_node_treeview_chunck(arr, chunkSize) {
      const res = [];
      for (let i = 0; i < arr.length; i += chunkSize) {
          const chunk = arr.slice(i, i + chunkSize);
          res.push(chunk);
      }
      return res;
    },

    async summitmovefile2() {
      try{
        this.cancelCheck = false
        this.check_show_progress = true 
        this.check_dis_button = true
        this.heightGroupItem = 0
        // ## จัด array ไว้เป็นตย.ในการเรียง
        let arrayIdBaseOn = this.nonsuccess_group_recovery.map((item) => item.file_id) 
        // ## เรียงตาม array base on  เพราะว่าจอนเลือก move จะเลือกแบบ ไม่ได้เรียงตามที่เป็นอยู่
        this.multiitemwaitmove_v2 = this.multiitemwaitmove_v2.length <= 1 ? 
        this.multiitemwaitmove_v2 : this.multiitemwaitmove_v2.sort(function (a, b) {
          return arrayIdBaseOn.indexOf(a.file_id) - arrayIdBaseOn.indexOf(b.file_id);
        })
        
        // ## 
        let chuck_group_file_id = this.multiitemwaitmove_v2.length <= 1 ? 
        [this.multiitemwaitmove_v2] : await this.file_id_chunk(this.multiitemwaitmove_v2, 5)

        for(var i = 0; i < chuck_group_file_id.length; i++){ // [[123], [23]]
          var check_cancel_round = false
          var check_error_round = false
          this.disable_treeview_loading = true
          if(this.cancelCheck){
          console.log("Working Immidiat")
          this.nonsuccess_group_recovery.filter((item) => item.file_status === this.$t("multirecovery.waittomove")).forEach(async (item) => {
            item.file_status =  this.$t('multirecovery.recovery_cancel') 
              if(item.file_type === 'folder'){
                await this.updatefilestatus_failmove(item.children)
              }
          })
            this.check_dis_button = false;
            this.show_all_select = false
            this.nonsuccess_loading = false 
            this.check_move_dis_button = false
            this.disable_treeview_loading = false
            this.$emit('clearpathmoveto')
            this.checkAll = false 
          break
        }

          const promises = await chuck_group_file_id[i].map(async item => { // ## ส่ง group ของ file_id
              item.file_status = this.$t("multirecovery.movingfilefolder");
              
              // ## เก็บ file_id ของโฟลเดอรที่มีลูก
              var finally_sub_fileId
              let temp_folder = []
              let temp_file = []
              finally_sub_fileId = await this.depressed_subfilefolder_id(
              item.file_type === 'folder' ? item.children : [], temp_folder, temp_file)

              let payload = {
                data_id:  item.file_id,
                data_type: item.file_type === "folder" ? "folder" : "file",
                account_id: this.dataAccountId,
                folder_id_move_to: this.filefoldermoveto,
                user_id: this.dataUsername,
                sub_folder: finally_sub_fileId.tempfolder,
                sub_file: finally_sub_fileId.tempfile
              };
            const responseProm =  await this.fn_move_filefolder_v2 (item, payload)
            return responseProm
          })
          await Promise.all(promises)
          .then(async(AllRes) => {
            for(const itemFile of AllRes){
              // ## Response OK
              if(itemFile.status === 'OK'){
                this.success_rocovery.push(this.multiitemwaitmove_v2.find((item) => item.file_id === itemFile.file_id ))
                if(itemFile.file_type === 'folder'){
                  let countmistake = 0
                  if(await this.updatefilestatus(itemFile.children, itemFile.data_miss, countmistake) > 0){ // ## เช็ค datamiss ที่ส่งมาบาง fileid ของลูกๆนั้น
                    let findIndexItem = this.multiitemwaitmove_v2.findIndex((item) => item.file_id === itemFile.file_id)
                    this.multiitemwaitmove_v2[findIndexItem]["message"] = 'บางไฟล์/โฟลเดอร์กู้คืนไม่สำเร็จ'
                  }
                }
              } 
              // ## Response File/Folder Error (ไม่เบรค)
              else if (itemFile.status === 'FILE/FOLDER ERROR') {
                let findIndexError = this.multiitemwaitmove_v2.findIndex((item) => item.file_id === itemFile.file_id)
                this.multiitemwaitmove_v2[findIndexError]["file_status"] = this.$t("multirecovery.recovfail");
                if(this.multiitemwaitmove_v2[i].file_type === 'folder'){
                  await this.updatefilestatus_failmove(itemFile.children)
                }
              } 
              else if (itemFile.status === 'AXIOS ERROR') {
              check_error_round = true
              } else {
                check_cancel_round = true
              }
            }
          })

          let group_element_node_ = await this.slice_node_treeview_chunck(Array.from(document.getElementsByClassName('v-treeview-movefile')[0].children),  5)
          // console.log
          this.scrollToBottom_v2(group_element_node_[i][group_element_node_[i].length - 1])
          // let sum = 0
          // group_element_node_[i].forEach((item) => sum += item.offsetHeight )
          // this.heightGroupItem += sum
          // this.scrollToBottom_v3(this.heightGroupItem) //group_element_node_[i].length - 1

          if(check_error_round){
            chuck_group_file_id[i].forEach(async (item) => {
              if(item.file_status === this.$t("multirecovery.movingfilefolder")){
                item.file_status = this.$t("multirecovery.recovfail");
                if(item.file_type === 'folder'){
                  await this.updatefilestatus_failmove(item.children)
                }
              }
            })
            
            this.check_dis_button = false;
            this.show_all_select = false
            this.nonsuccess_loading = false 
            this.check_move_dis_button = false
            this.disable_treeview_loading = false
            this.$emit('clearpathmoveto')
            this.checkAll = false 
            break
          }

         

          if(chuck_group_file_id.length - 1 === i){
              this.check_dis_button = false
              await this.timeout(600)
              this.disable_tab_success = this.success_rocovery.length > 0 ? false : true
              this.disable_treeview_loading = false
              this.$emit('clearpathmoveto')
              this.multiitemwaitmove_v2 = []
              this.checkAll = false 
              if(this.nonsuccess_group_recovery.filter((item) => item.file_status === 'Wait to move' || item.file_status === 'รอย้ายไฟล์').length <= 0){
                this.tab = 1
                this.disable_tab_nonesuccess = true
                this.show_all_select = false
              }
          }

        }
      } catch(err) {
        console.log("ERROR Cat Meow ", err )
      }
    },

    async fn_move_filefolder_v2 (fileItem, payload) {
      let auth = await gbfGenerate.generateToken();
        return await this.axios
          .post(
            process.env.VUE_APP_SERVICE_DOWNLOAD_API + "/api/move_data_from_trash",
            payload,
            { 
              headers: { Authorization: auth.code },
              // cancelToken: this.cancelSource.token,
            }
          )
          .then((response) => {
            if (response.data.status === "OK") {
              let indexUpdateMessage = this.multiitemwaitmove_v2.findIndex((item) => item.file_id === fileItem.file_id);
              this.multiitemwaitmove_v2[indexUpdateMessage].file_status = this.$t("multirecovery.recovsuccess");
              this.multiitemwaitmove_v2[indexUpdateMessage].file_status_message = response.data.message;
              return new Promise((resolve, reject) => {
                setTimeout(()=>{
                  resolve({
                      status: 'OK', 
                      message: 'Success', 
                      file_id: this.multiitemwaitmove_v2[indexUpdateMessage].file_id,
                      file_type: this.multiitemwaitmove_v2[indexUpdateMessage].file_type,
                      children: this.multiitemwaitmove_v2[indexUpdateMessage].file_type === 'folder' ? 
                      this.multiitemwaitmove_v2[indexUpdateMessage].children : [],
                      data_miss:  []
                  })
                },500) 
                })

            } else {
              let indexUpdateMessage = this.multiitemwaitmove_v2.findIndex((item) => item.file_id === fileItem.file_id);
              return new Promise((resolve, reject) => {
                  setTimeout(() => {
                    resolve({
                      status: 'FILE/FOLDER ERROR', 
                      message: 'Not success', 
                      file_id: this.multiitemwaitmove_v2[indexUpdateMessage].file_id,
                      file_type: this.multiitemwaitmove_v2[indexUpdateMessage].file_type,
                      children: this.multiitemwaitmove_v2[indexUpdateMessage].file_type === 'folder' ? 
                      this.multiitemwaitmove_v2[indexUpdateMessage].children : [],})
                  },500)
              }) 
            }
          })
          .catch((error) => {
            let indexUpdateMessage = this.multiitemwaitmove_v2.findIndex((item) => item.file_id === fileItem.file_id);
            return new Promise((resolve, reject) => {
              setTimeout(() => {
                resolve({
                  status: 'AXIOS ERROR', 
                  message: this.$t("movefiles.cannotmovefile"),
                  file_id: this.multiitemwaitmove_v2[indexUpdateMessage].file_id,
                  file_type: this.multiitemwaitmove_v2[indexUpdateMessage].file_type,
                })
              },500)
            })
          });
    },
    ///////////////////////////

    // ## CANCEL AXIOS
     async cancelRecovery (){
      //this.cancelSource.cancel('Cancel All Group')
      // console.log("Check Cancel Worling Here")
      this.cancelCheck = true
     },

     async cancelRecovery_doc_inoutbox () {
      this.cancelSource_doc.cancel()
     }
    


    


  },
};
</script>
<style scoped>

 .scroll{
  width: 670px;
	max-height: 500px; 
	overflow: scroll;
	background: lightgrey;
	margin-bottom: 20px;
}

</style>